/* eslint-disable @typescript-eslint/no-explicit-any */
import { Code, Effects, Event, State, Token, TokenizeContext } from 'micromark-util-types';
import { splice } from 'micromark-util-chunked';
import { resolveAll } from 'micromark-util-resolve-all';
import { classifyCharacter } from 'micromark-util-classify-character';
import { Extension as FromMarkdownExtension } from 'mdast-util-from-markdown';
import { Info, Options as ToMarkdownExtension, State as ToMarkdownState } from 'mdast-util-to-markdown';
import { Parents } from 'mdast';

const repeat = (character: string, count: number) => {
  const chars: string[] = [];

  for (let i = 0; i < count; i++) {
    chars.push(character);
  }

  return chars.join('');
};

export function shxCustomSyntaxExtension(
  characterCode: number,
  markerLength: number,
  generatedNodeType: string,
  isLeaf = false
) {
  const tokenizer = {
    tokenize: tokenizeCustom,
    resolveAll: resolveAllCustom,
  };

  return {
    text: {
      [characterCode]: tokenizer,
    },
    insideSpan: {
      null: [tokenizer],
    },
    attentionMarkers: {
      null: [characterCode],
    },
  };

  function resolveAllCustom(events: Event[], context: TokenizeContext) {
    let index = -1;

    // Walk through all events.
    while (++index < events.length) {
      // Find a token that can close.
      if (
        events[index][0] === 'enter' &&
        events[index][1].type === `${generatedNodeType}SequenceTemporary` &&
        events[index][1]._close
      ) {
        let open = index;

        // Now walk back to find an opener.
        while (open--) {
          // Find a token that can open the closer.
          if (
            events[open][0] === 'exit' &&
            events[open][1].type === `${generatedNodeType}SequenceTemporary` &&
            events[open][1]._open &&
            // If the sizes are the same:
            events[index][1].end.offset - events[index][1].start.offset ===
              events[open][1].end.offset - events[open][1].start.offset
          ) {
            events[index][1].type = `${generatedNodeType}Sequence` as any;
            events[open][1].type = `${generatedNodeType}Sequence` as any;

            const custom: Token = {
              type: generatedNodeType as any,
              start: Object.assign({}, events[open][1].start),
              end: Object.assign({}, events[index][1].end),
            };

            const text: Token = isLeaf
              ? {
                  type: `theText` as any,
                  start: Object.assign({}, events[open][1].end),
                  end: Object.assign({}, events[index][1].start),
                }
              : {
                  type: `${generatedNodeType}Text` as any,
                  start: Object.assign({}, events[open][1].end),
                  end: Object.assign({}, events[index][1].start),
                };

            // Opening.
            const nextEvents: Event[] = [
              ['enter', custom, context],
              ['enter', events[open][1], context],
              ['exit', events[open][1], context],
              ['enter', text, context],
            ];
            const insideSpan = context.parser.constructs.insideSpan.null;
            if (insideSpan) {
              // Between.
              splice(nextEvents, nextEvents.length, 0, resolveAll(insideSpan, events.slice(open + 1, index), context));
            }

            // Closing.
            splice(nextEvents, nextEvents.length, 0, [
              ['exit', text, context],
              ['enter', events[index][1], context],
              ['exit', events[index][1], context],
              ['exit', custom, context],
            ]);
            splice(events, open - 1, index - open + 3, nextEvents);
            index = open + nextEvents.length - 2;
            break;
          }
        }
      }
    }

    index = -1;
    while (++index < events.length) {
      if (events[index][1].type === `${generatedNodeType}SequenceTemporary`) {
        events[index][1].type = 'data';
      }
    }
    return events;
  }

  function tokenizeCustom(this: TokenizeContext, effects: Effects, ok: State, nok: State) {
    const previous = this.previous;
    const events = this.events;
    let size = 0;
    return start;

    function start(code: Code) {
      if (previous === characterCode && events[events.length - 1][1].type !== 'characterEscape') {
        return nok(code);
      }
      effects.enter(`${generatedNodeType}SequenceTemporary` as any);
      return more(code);
    }

    function more(code: Code) {
      const before = classifyCharacter(previous);
      if (code === characterCode) {
        // if this is the end, exit
        if (size > markerLength - 1) return nok(code);
        effects.consume(code);
        size++;
        return more;
      }

      if (size < markerLength) return nok(code);
      const token = effects.exit(`${generatedNodeType}SequenceTemporary` as any);
      const after = classifyCharacter(code);
      token._open = !after || (after === 2 && Boolean(before));
      token._close = !before || (before === 2 && Boolean(after));
      return ok(code);
    }
  }
}

export function shxCustomSyntaxFromMarkdown(nodeType: string, isLeaf = false): FromMarkdownExtension {
  if (isLeaf) {
    return {
      enter: {
        [nodeType]: function (this, token) {
          this.enter({ type: nodeType as any, theText: '', children: [] } as any, token);
        },
      },
      exit: {
        [nodeType]: function (this, token) {
          const current = this.stack[this.stack.length - 1];
          (current as any).children = [];
          this.exit(token);
        },
        theText: function (this, token) {
          const theText = this.sliceSerialize(token).trim();
          const current = this.stack[this.stack.length - 1];
          (current as any).theText = theText;
        },
      },
    };
  }

  return {
    enter: {
      [nodeType]: function (this, token) {
        this.enter({ type: nodeType as any, children: [] }, token);
      },
    },
    exit: {
      [nodeType]: function (this, token) {
        this.exit(token);
      },
    },
  };
}

export function shxCustomSyntaxToMarkdown(
  markerCharacter: string,
  markerLength: number,
  nodeType: string,
  isLeaf = false
): ToMarkdownExtension {
  const marker = repeat(markerCharacter, markerLength);

  return {
    unsafe: [
      {
        character: markerCharacter,
        inConstruct: 'phrasing',
        notInConstruct: [
          'autolink',
          'destinationLiteral',
          'destinationRaw',
          'reference',
          'titleQuote',
          'titleApostrophe',
        ],
      },
    ],
    handlers: {
      [nodeType]: (node: any, parents: Parents, state: ToMarkdownState, info: Info) => {
        const tracker = state.createTracker(info);
        const exit = state.enter(nodeType as any);
        let value = tracker.move(marker);
        if (isLeaf) {
          value += tracker.move(node.theText);
        } else {
          value += state.containerPhrasing(node, {
            ...tracker.current(),
            before: value,
            after: markerCharacter,
          });
        }

        value += tracker.move(marker);
        exit();
        return value;
      },
    },
  };
}
