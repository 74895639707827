import React, { useState } from 'react';
import { DateTime } from 'luxon';
import Calendar from 'react-calendar';
import { CalendarCalloutItem, calendarSymbols, themeColours } from './helpers/dm.js';
import { getStringSettingValue } from '@soulhx/user-settings';
import { LegendItems } from './helpers/LegendItems.js';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useBulkUserSettings } from '../../../hooks/BulkSettings.js';
import { StandardButton, ClientSideErrorLoading } from '@soulhx/ui-common';

interface ICalendarWidget {
  dateToShow: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  selectNewDate(newDate: Date): void;
  startDateChange(newDate: Date): void;
  callouts: CalendarCalloutItem[];
  settingName?: string;
  hardcodedSelectedValues?: string[];
}
export const CalendarWidget = ({
  dateToShow,
  minDate,
  maxDate,
  selectNewDate,
  startDateChange,
  callouts,
  settingName,
  hardcodedSelectedValues,
}: ICalendarWidget) => {
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const { settings } = useBulkUserSettings();
  const { t } = useTranslation();

  if (!hardcodedSelectedValues && !settingName)
    return <ClientSideErrorLoading>{t('commonComponents.calendar.configError') as string}</ClientSideErrorLoading>;

  const selectedValues =
    hardcodedSelectedValues ||
    getStringSettingValue(settingName!, settings)
      .split(',')
      .filter((i) => i.length > 0);

  const availableCallouts =
    selectedValues.length < 1 ? [] : callouts.filter((c) => selectedValues.indexOf(c.name) >= 0);

  if (availableCallouts.length > themeColours.length) {
    return <ClientSideErrorLoading>{t('commonComponents.calendar.tooManyItemsError')}</ClientSideErrorLoading>;
  }

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Calendar
            value={dateToShow.toJSDate()}
            minDate={minDate ? minDate.toJSDate() : undefined}
            maxDate={maxDate ? maxDate.toJSDate() : undefined}
            onClickDay={(value) => {
              selectNewDate(value);
            }}
            onActiveStartDateChange={({ activeStartDate }) => {
              startDateChange(activeStartDate!);
            }}
            returnValue="end"
            tileContent={({ date }) => {
              if (availableCallouts.length < 1) return <p key={`tile-${date.getUTCMilliseconds()}`}>&nbsp;</p>;

              const highlights: JSX.Element[] = [];
              const calDate = DateTime.fromJSDate(date).toISODate()!;

              for (let i = 0; i < availableCallouts.length; i++) {
                if (availableCallouts[i].dates.has(calDate)) {
                  highlights.push(
                    <span key={`span-callout-${date.getUTCMilliseconds()}[${i}]`} className={`text-${themeColours[i]}`}>
                      {calendarSymbols[i]}
                    </span>
                  );
                }
              }

              if (highlights.length < 1) {
                highlights.push(<span key={`tile-${date.getUTCMilliseconds()}`}>&nbsp;</span>);
              }
              return <p key={`tile-${date.getUTCMilliseconds()}`}>{highlights}</p>;
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h6>
            <StandardButton
              type="showHide"
              tooltip={t('commonComponents.calendar.legendTooltip')}
              done={showLegend}
              onClick={() => setShowLegend(!showLegend)}
            >
              {t('commonComponents.calendar.legend')}
            </StandardButton>
          </h6>
        </Col>
        {showLegend && (
          <Col xs="12">
            <LegendItems options={availableCallouts.map((c) => c.name)} />
          </Col>
        )}
      </Row>
    </Container>
  );
};
