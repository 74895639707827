import { createRootEditorSubscription$, realmPlugin } from '@mdxeditor/editor';
import { TextNode } from 'lexical';
import { retext } from 'retext';
import smartypants from 'retext-smartypants';

const findFirstDiffPos = (stringA: string, stringB: string): number => {
  let i = 0;
  if (stringA === stringB) return -1;
  while (stringA[i] === stringB[i]) i++;
  return i;
};

export const smartypantsPlugin = realmPlugin({
  init(r) {
    r.pub(createRootEditorSubscription$, (editor) => {
      return editor.registerNodeTransform(TextNode, (node) => {
        const oldString = node.getTextContent();

        const processedVFile = retext()
          .use(smartypants, {
            backticks: false,
            dashes: 'oldschool',
          })
          .processSync(oldString);
        const processedString = String(processedVFile);

        const changePos = findFirstDiffPos(oldString, processedString);
        if (changePos === -1) return;

        node.setTextContent(processedString);
        node.select(changePos + 1, changePos + 1);
      });
    });
  },
});
