import React from 'react';
import { ButtonWithTooltip, usePublisher } from '@mdxeditor/editor';
import { openBibleLinkEditDialog$ } from '../bl-dialog/index.js';
import { useTranslation } from 'react-i18next';

export const CreateBibleLink = () => {
  const { t } = useTranslation(['mdx']);
  const openBibleLinkDialog = usePublisher(openBibleLinkEditDialog$);

  return (
    <ButtonWithTooltip
      aria-label={t('mdx:toolbar.bibleLink')}
      title={t('mdx:toolbar.bibleLink')}
      onClick={() => openBibleLinkDialog()}
    >
      B
    </ButtonWithTooltip>
  );
};
