import React, { useCallback } from 'react';
import { BaseActionEntry } from '@soulhx/fs-common';
import { DateTime } from 'luxon';
import { Alert, ListGroup } from 'react-bootstrap';
import { SetMessageFunction } from '../../hooks/ErrorsAndWarnings.js';
import { useCreateEntryMutation } from '../../services/ActionsService.js';
import { ActionCheckItem } from './ActionCheckItem.js';
import { ActionWidgetFormPlaceholder } from './helpers/ActionWidgetFormPlaceholder.js';
import { useTranslation, Trans } from 'react-i18next';
import { useGetActionTypes } from '../../hooks/GetActions.js';
import { useGetActionEntriesForDay } from '../../hooks/GetActionEntries.js';
import { ErrorLoadingDataMessage } from '@soulhx/ui-common';

interface IActionWidgetForm {
  date: DateTime;
  setErrorMessage: SetMessageFunction;
  showDetails: boolean;
  showLongNames?: boolean;
}
export const ActionWidgetForm = ({ date, setErrorMessage, showDetails, showLongNames = true }: IActionWidgetForm) => {
  const { entries, entriesError, entriesLoading } = useGetActionEntriesForDay(
    date.year.toString(),
    date.month.toString(),
    date.day.toString()
  );
  const { actionTypes, actionTypeError, actionTypesLoading } = useGetActionTypes({ longNames: showLongNames });
  const [createEntry] = useCreateEntryMutation();
  const { t } = useTranslation(['do']);

  const itemClicked = useCallback(
    (id: string) => {
      const currentTime = DateTime.now();
      const dateToUse = DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: currentTime.hour,
        minute: currentTime.minute,
        second: currentTime.second,
        millisecond: currentTime.millisecond,
      });
      const newEntry: BaseActionEntry = {
        actionType: id,
        completed: true,
        entryDate: dateToUse.toISO()!,
      };

      createEntry(newEntry)
        .unwrap()
        .catch(() => {
          setErrorMessage(t('do:actionsWidget.errorSavingChanges'));
        });
    },
    [createEntry, setErrorMessage, date, t]
  );

  if (entriesLoading || actionTypesLoading) {
    return <ActionWidgetFormPlaceholder />;
  }
  if (entriesError || actionTypeError) {
    return <ErrorLoadingDataMessage errors={[entriesError, actionTypeError]} />;
  }

  if (actionTypes!.length > 0) {
    return (
      <ListGroup className="p-1" variant="flush">
        {actionTypes!.map((item) => (
          <ActionCheckItem
            key={item.id}
            item={item}
            clickFunction={itemClicked}
            showDetails={showDetails}
            completedEntries={entries!.filter((ad) => ad.actionType === item.id && ad.completed)}
          />
        ))}
      </ListGroup>
    );
  }

  return (
    <Alert>
      <p>{t('do:actionsWidget.noActionsAvailable')}</p>{' '}
      <p>
        <Trans i18nKey="do:actionsWidget.noActionsAvailableLink">
          Go to <Alert.Link href="/do/config">the Do page</Alert.Link> to set some up.
        </Trans>
      </p>
    </Alert>
  );
};
