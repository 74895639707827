import React, { KeyboardEvent, useRef, useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { getHtmlFromMD } from '../../../hooks/md/index.js';
import { useWindowSize } from '../../../hooks/WindowSize.js';
import fileDownload from 'js-file-download';
import { KeyMap, configure as hotkeyConfigure, HotKeys } from 'react-hotkeys';
import { getToolbar } from './helpers/md-commands.js';
import { getControlPixelHeight } from './helpers/TextAreaTextApi.js';
import { MDPreview } from './MDPreview.js';
import { FullScreenButton } from './helpers/FullScreenButton.js';
import { useTranslation } from 'react-i18next';
import { CheatSheetTutorial } from './CheatSheetTutorial.js';
import { UserMDTemplate } from '@soulhx/fs-common';
import { SHXButton } from '@soulhx/ui-common';

const PREVIEW_DELAY = 500;

export interface IMarkdownBox {
  /**
   * The markdown that is being edited
   */
  content: string;

  /**
   * A callback function passed by the calling application that will
   * be called any time the MD is updated
   *
   * @param newContent Used to pass back the MD as currently in the component
   */
  changeCallback: (newContent: string) => void;

  /**
   * The default version of the Bible (if any) to be used in rendering
   * the markdown (e.g. for Bible links)
   */
  defaultVersion?: string;

  /**
   * A larger passage of Scripture (if any) under which this content
   * is being created (e.g. for Bible links)
   */
  passageContext?: string;

  /**
   * Controls whether the row(s) of buttons should
   * be shown at the top of the editor. If set to `false` keyboard
   * shortcuts will still work, the buttons just won't be shown on
   * the screen.
   *
   * Defaults to `true`
   */
  showToolbar?: boolean;

  /**
   * Indicates whether this component should be
   * allowed to provide a "full screen" option for editing
   *
   * Defaults to `false`
   */
  fullScreenOption?: boolean;

  /**
   * If passed, and if `fullScreenOption` is `true`,
   * will be shown as a title when editing MD in full screen mode.
   *
   * Not mandatory evne if `fullScreenOption` is set to `true`.
   */
  fullScreenTitle?: string;

  /**
   * Indicates that all UI should be hidden
   * except for the text box itself
   *
   * Defaults to `false`
   */
  hideAllControls?: boolean;

  /**
   * The number of lines of text to be shown in the editor box.
   * (Doesn't apply to full screen mode.)
   *
   * Defaults to `20`
   */
  height?: number;

  /**
   * Indicates that the MD should be displayed in the editor box, but
   * that it should not be editable. If this param is set to `true` it's
   * recommended that `changeCallback` be set to an empty / do-nothing
   * function, though it will never be called anyway.
   *
   * Defaults to `false`
   */
  readOnly?: boolean;

  templates?: UserMDTemplate[];
}

export const MarkdownBox = ({
  content,
  changeCallback,
  defaultVersion,
  passageContext,
  showToolbar = true,
  fullScreenOption = false,
  fullScreenTitle,
  hideAllControls = false,
  height = 20,
  readOnly = false,
  templates,
}: IMarkdownBox) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const editorRef = useRef<HTMLTextAreaElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const viewerRef = useRef<HTMLDivElement>(null);
  const linksRef = useRef<HTMLDivElement>(null);
  const [preventScrollEvent, setPreventScrollEvent] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const [previewContent, setPreviewContent] = useState(content);
  const [previewTimer, setPreviewTimer] = useState<NodeJS.Timeout | null>(null);
  const [fs, setFS] = useState<boolean>(false);
  const { t } = useTranslation(['mde']);
  const [showTutorial, setShowTutorial] = useState(false);

  let keyMap: KeyMap = {};
  let handlers = {};

  const renderedToolbar = getToolbar(templates, fs).groups.map((g, index) => (
    <ButtonGroup key={`buttongroup-${index}`} className="me-1">
      {g.buttons.map((b, buttonIndex) => {
        const clickFn = () => {
          b.execute(editorRef);
        };
        if (b.keyboardShortcut) {
          keyMap = { ...keyMap, [b.name]: b.keyboardShortcut };
          handlers = {
            ...handlers,
            [b.name]: (event: KeyboardEvent) => {
              clickFn();
              event.preventDefault();
              event.stopPropagation();
            },
          };
        }
        const title = b.keyboardShortcut ? `${b.name} (${b.keyboardShortcut})` : b.name;
        return (
          <Button variant="outline-dark" onClick={clickFn} key={`button-${index}-${buttonIndex}`} title={title}>
            {b.buttonContents}
          </Button>
        );
      })}
    </ButtonGroup>
  ));

  const getEditorLineHeight = () => {
    if (!editorRef.current || !fs) {
      return height;
    }

    const linksHeight = linksRef.current?.clientHeight || 0;
    const buttonsHeight = toolbarRef.current?.clientHeight || 0;
    const windowHeight = windowSize.height;
    const workingHeight = windowHeight - (linksHeight + buttonsHeight);
    const fontHeight = parseFloat(getComputedStyle(editorRef.current).fontSize);
    return workingHeight / fontHeight / 2 || height;
  };

  hotkeyConfigure({
    ignoreTags: [],
    ignoreEventsCondition: () => false,
    stopEventPropagationAfterHandling: true,
    simulateMissingKeyPressEvents: false,
    ignoreKeymapAndHandlerChangesByDefault: false,
  });

  const fullUI = (
    <Container fluid>
      {!hideAllControls && showToolbar && (
        <Row>
          <Col xs="12">
            <ButtonToolbar ref={toolbarRef} aria-label={t('mde:mdb.toolbarLabel')}>
              {renderedToolbar}
            </ButtonToolbar>
          </Col>
        </Row>
      )}
      {!showPreview && (
        <Row>
          <Col xs={fs ? '6' : '12'} className="p-0">
            <HotKeys keyMap={keyMap} handlers={handlers} allowChanges>
              <Form.Control
                ref={editorRef}
                aria-label={t('mde:mdb.mdBoxLabel')}
                className="shx-md-editor"
                as="textarea"
                rows={getEditorLineHeight()}
                value={content}
                onChange={(e) => {
                  const newText = e.currentTarget.value;
                  changeCallback(newText);

                  if (previewTimer) clearTimeout(previewTimer);

                  setPreviewTimer(
                    setTimeout(() => {
                      setPreviewContent(newText);
                      setPreviewTimer(null);
                    }, PREVIEW_DELAY)
                  );
                }}
                disabled={readOnly}
                onScroll={(e) => {
                  if (preventScrollEvent) {
                    setPreventScrollEvent(false);
                    return;
                  }

                  setPreventScrollEvent(true);
                  if (viewerRef.current) {
                    viewerRef.current.scrollTop = e.currentTarget.scrollTop;
                  }
                }}
              />
            </HotKeys>
          </Col>
          {fs && (
            <Col>
              <div
                ref={viewerRef}
                className="overflow-auto"
                onScroll={(e) => {
                  if (preventScrollEvent) {
                    setPreventScrollEvent(false);
                    return;
                  }

                  setPreventScrollEvent(true);
                  if (editorRef.current) {
                    editorRef.current.scrollTop = e.currentTarget.scrollTop;
                  }
                }}
                style={{ height: `${getControlPixelHeight(editorRef)}px` }}
              >
                <MDPreview
                  content={previewContent}
                  defaultVersion={defaultVersion}
                  passageContext={passageContext}
                  className="reading-text"
                />
              </div>
            </Col>
          )}
        </Row>
      )}
      {!hideAllControls && (
        <Row ref={linksRef}>
          <Col xs="12">
            <SHXButton variant="outline-secondary" onClick={() => setShowTutorial(true)}>
              {t('mde:mdb.tutorialBtn')}
            </SHXButton>

            <Modal show={showTutorial} onHide={() => setShowTutorial(false)}>
              <Modal.Header closeButton>{t('mde:mdb.tutorialHeader')}</Modal.Header>
              <Modal.Body>
                <CheatSheetTutorial />
              </Modal.Body>
            </Modal>

            {fullScreenOption && <FullScreenButton isFullScreen={fs} stateSetter={setFS} />}

            <SHXButton
              variant="outline-secondary"
              onClick={() => {
                const formattedHTML = getHtmlFromMD(content, { defaultVersion, scriptureContext: passageContext });
                fileDownload(formattedHTML, t('mde:mdb.downloadFileName'));
              }}
            >
              {t('mde:mdb.exportBtn')}
            </SHXButton>
          </Col>
        </Row>
      )}
      {!fs && !hideAllControls && (
        <Row>
          <Col className="d-grid gap-2">
            <Button variant="outline-primary" onClick={() => setShowPreview(!showPreview)}>
              {showPreview ? t('mde:mdb.hidePreviewBtn') : t('mde:mdb.showPreviewBtn')}
            </Button>

            {showPreview && (
              <>
                <MDPreview
                  content={previewContent}
                  shaded
                  defaultVersion={defaultVersion}
                  passageContext={passageContext}
                  className="reading-text"
                />

                <Button variant="outline-primary" onClick={() => setShowPreview(!showPreview)}>
                  {t('mde:mdb.hidePreviewBtn')}
                </Button>
              </>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );

  if (!fs) return <>{fullUI}</>;

  return (
    <Modal show onHide={() => setFS(false)} keyboard={false} fullscreen>
      {fullScreenTitle && (
        <Modal.Header closeButton>
          <Modal.Title>{fullScreenTitle}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={{ maxWidth: '1500px' }} className="ms-auto me-auto">
        {fullUI}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setFS(false)}>{t('mde:mdb.modal.closeBtn')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MarkdownBox;
