import React, { useEffect } from 'react';
import { isRefValid } from '@soulhx/fs-common';
import * as yup from 'yup';
import { createCommand, LexicalCommand } from 'lexical';
import { Formik, FormikProps } from 'formik';
import { Form, FormGroup, InputGroup } from 'react-bootstrap';
import * as Popover from '@radix-ui/react-popover';
import { activeEditor$, editorRootElementRef$, onWindowChange$, useCellValues, usePublisher } from '@mdxeditor/editor';
import {
  bibleLinkDialogState$,
  cancelBibleLinkEdit$,
  removeBibleLink$,
  switchFromPreviewToBibleLinkEdit$,
  updateBibleLink$,
} from './index.js';
import { useTranslation } from 'react-i18next';
import { BibleLink } from '../components/BibleLink.js';
import { StandardButton } from '@soulhx/ui-common';

const schema = yup.object({
  reference: yup
    .string()
    .required('')
    .test('valid-ref', '', (value) => {
      if (value === undefined || value.trim().length < 1) return false;

      return isRefValid(value);
    }),
  renderedText: yup.string().optional(),
  bibleVersion: yup.string().optional(),
  simplified: yup.boolean(),
  hideVersion: yup.boolean(),
});
type ValuesSchema = yup.InferType<typeof schema>;

export const OPEN_BIBLELINK_DIALOG: LexicalCommand<undefined> = createCommand();

interface IBibleLinkEditForm {
  reference: string;
  renderedText?: string;
  bibleVersion?: string;
  simplified: boolean;
  hideVersion: boolean;
  onSubmit: (value: {
    reference: string | undefined;
    renderedText: string | undefined;
    bibleVersion: string | undefined;
    flags: string | undefined;
  }) => void;
  onCancel: () => void;
}
export const BibleLinkEditForm = ({
  reference,
  renderedText,
  bibleVersion,
  simplified,
  hideVersion,
  onSubmit,
  onCancel,
}: IBibleLinkEditForm) => {
  const { t } = useTranslation();

  const initialValues: ValuesSchema = {
    reference,
    renderedText: renderedText || '',
    bibleVersion: bibleVersion || '',
    simplified,
    hideVersion,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        const renderedText = values.renderedText && values.renderedText.length > 0 ? values.renderedText : undefined;
        const bibleVersion = values.bibleVersion && values.bibleVersion.length > 0 ? values.bibleVersion : undefined;
        let flags = '';
        if (values.hideVersion) flags += 'h';
        if (values.simplified) flags += 's';

        onSubmit({
          reference: values.reference,
          renderedText,
          bibleVersion,
          flags,
        });
      }}
      validateOnBlur
      validateOnChange
    >
      {(fp: FormikProps<ValuesSchema>) => (
        <Form noValidate onSubmit={fp.handleSubmit}>
          <InputGroup className="mb-1">
            <InputGroup.Text>
              <Form.Label>{t('mdx:createBibleLink.reference')}</Form.Label>
            </InputGroup.Text>

            <Form.Control
              type="search"
              name="reference"
              id="reference"
              value={fp.values.reference}
              onChange={fp.handleChange}
              onBlur={fp.handleBlur}
              isInvalid={!!fp.errors.reference}
              autoFocus
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>
              <Form.Label>{t('mdx:createBibleLink.renderedText')}</Form.Label>
            </InputGroup.Text>

            <Form.Control
              name="renderedText"
              id="renderedText"
              value={fp.values.renderedText}
              onChange={fp.handleChange}
              onBlur={fp.handleBlur}
              isInvalid={!!fp.errors.renderedText}
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>
              <Form.Label>{t('mdx:createBibleLink.bibleVersion')}</Form.Label>
            </InputGroup.Text>

            <Form.Control
              name="bibleVersion"
              id="bibleVersion"
              value={fp.values.bibleVersion}
              onChange={fp.handleChange}
              onBlur={fp.handleBlur}
              isInvalid={!!fp.errors.bibleVersion}
            />
          </InputGroup>

          <FormGroup>
            <Form.Check
              type="checkbox"
              name="simplified"
              id="simplified"
              label={t('mdx:createBibleLink.simplified')}
              aria-label={t('mdx:createBibleLink.simplified')}
              checked={fp.values.simplified}
              onChange={fp.handleChange}
            />

            <Form.Check
              type="checkbox"
              name="hideVersion"
              id="hideVersion"
              label={t('mdx:createBibleLink.hideVersion')}
              aria-label={t('mdx:createBibleLink.hideVersion')}
              checked={fp.values.hideVersion}
              onChange={fp.handleChange}
            />
          </FormGroup>

          <div>
            <StandardButton type="save" submit disabled={!fp.isValid}>
              {t('mdx:dialogControls.save')}
            </StandardButton>

            <StandardButton type="cancel" onClick={() => onCancel()} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const BibleLinkDialog = () => {
  const [activeEditor, bibleLinkDialogState, editorRootElement] = useCellValues(
    activeEditor$,
    bibleLinkDialogState$,
    editorRootElementRef$
  );
  const publishWindowChange = usePublisher(onWindowChange$);
  const updateBibleLink = usePublisher(updateBibleLink$);
  const cancelBibleLinkEdit = usePublisher(cancelBibleLinkEdit$);
  const switchFromPreviewToBibleLinkEdit = usePublisher(switchFromPreviewToBibleLinkEdit$);
  const removeBibleLink = usePublisher(removeBibleLink$);

  useEffect(() => {
    const update = () => {
      activeEditor?.getEditorState().read(() => {
        publishWindowChange(true);
      });
    };

    window.addEventListener('resize', update);
    window.addEventListener('scroll', update);

    return () => {
      window.removeEventListener('resize', update);
      window.removeEventListener('scroll', update);
    };
  }, [activeEditor, publishWindowChange]);

  return (
    <Popover.Root open={bibleLinkDialogState.type !== 'inactive'}>
      <Popover.Anchor
        className="mde-popover-anchor"
        data-visible={bibleLinkDialogState.type === 'edit'}
        style={{
          top: `${bibleLinkDialogState.rectangle?.top ?? 0}px`,
          left: `${bibleLinkDialogState.rectangle?.left ?? 0}px`,
          width: `${bibleLinkDialogState.rectangle?.width ?? 0}px`,
          height: `${bibleLinkDialogState.rectangle?.height ?? 0}px`,
        }}
      />

      <Popover.Portal container={editorRootElement?.current}>
        <Popover.Content
          className="mde-popover-content"
          sideOffset={5}
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
          key={bibleLinkDialogState.bibleLinkNodeKey}
        >
          {bibleLinkDialogState.type === 'edit' && (
            <BibleLinkEditForm
              reference={bibleLinkDialogState.reference}
              renderedText={bibleLinkDialogState.renderedText}
              bibleVersion={bibleLinkDialogState.bibleVersion}
              hideVersion={bibleLinkDialogState.hideVersion || false}
              simplified={bibleLinkDialogState.simplified || false}
              onSubmit={updateBibleLink}
              onCancel={cancelBibleLinkEdit.bind(null)}
            />
          )}

          {bibleLinkDialogState.type === 'preview' && (
            <>
              <BibleLink
                passage={bibleLinkDialogState.reference}
                hideVersion={bibleLinkDialogState.hideVersion}
                version={bibleLinkDialogState.bibleVersion}
              />

              <StandardButton type="edit" onClick={() => switchFromPreviewToBibleLinkEdit()} />

              <StandardButton
                type="delete"
                onClick={() => {
                  removeBibleLink({
                    reference: bibleLinkDialogState.reference,
                    renderedText: bibleLinkDialogState.renderedText,
                  });
                }}
              />
            </>
          )}
          <Popover.Arrow className="mde-popover-arrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
