import React from 'react';
import { DecoratorNode, LexicalEditor, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical';
import { Node, Parent, PhrasingContent } from 'mdast';
import { AllUpperEditor } from '../editors/AllUpperEditor.js';

export interface AllUpper extends Parent, Node {
  type: 'allupper';
  theText: string;
  children: PhrasingContent[];
}

export type SerializedAllUpperNode = Spread<
  {
    theText: string;
  },
  SerializedLexicalNode
>;

export class AllUpperNode extends DecoratorNode<JSX.Element> {
  __theText: string;

  static getType() {
    return 'allupper';
  }

  static clone(node: AllUpperNode): AllUpperNode {
    return new AllUpperNode(structuredClone(node.__theText), node.__key);
  }

  static importJSON(serializedNode: SerializedAllUpperNode): AllUpperNode {
    return $createAllUpperNode(serializedNode.theText);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'allupper',
      theText: this.__theText,
    };
  }

  getTheText() {
    return this.__theText;
  }

  setTheText(theText: string) {
    const writable = this.getWritable();
    writable.__theText = theText;
  }

  constructor(theText: string, key?: NodeKey) {
    super(key);
    this.__theText = theText;
  }

  createDOM() {
    const element = document.createElement('span');
    element.className = 'bg-secondary-subtle';
    return element;
  }

  exportDOM() {
    const element = document.createElement('span');

    const individualWords = this.__theText.split(' ');
    for (let i = 0; i < individualWords.length; i++) {
      const theWord = individualWords[i];

      const firstLetterSpan = document.createElement('span');
      firstLetterSpan.style.textTransform = 'uppercase';
      const firstLetter = theWord.substring(0, 1);
      const textNode = document.createTextNode(firstLetter);
      firstLetterSpan.append(textNode);
      element.append(firstLetterSpan);

      const restOfWordSpan = document.createElement('span');
      restOfWordSpan.style.textTransform = 'uppercase';
      restOfWordSpan.style.fontSize = '1.3ex';
      const restOfWord = theWord.substring(1, theWord.length);
      const restOfWordTextNode = document.createTextNode(restOfWord);
      restOfWordSpan.append(restOfWordTextNode);
      element.append(restOfWordSpan);

      if (i < individualWords.length - 1) {
        const spaceSpan = document.createElement('span');
        const spaceTextNode = document.createTextNode(' ');
        spaceSpan.append(spaceTextNode);
        element.append(spaceSpan);
      }
    }

    return { element };
  }

  decorate(editor: LexicalEditor) {
    return (
      <AllUpperEditor
        nodeKey={this.__key}
        text={this.__theText}
        editor={editor}
        changeCallback={(newText) => {
          editor.update(() => {
            this.setTheText(newText);
          });
        }}
      />
    );
  }

  updateDOM() {
    return false;
  }

  isInline() {
    return true;
  }
}

export function $isAllUpperNode(node: LexicalNode | null | undefined): node is AllUpperNode {
  return node instanceof AllUpperNode;
}

export function $createAllUpperNode(theText: string): AllUpperNode {
  return new AllUpperNode(theText);
}
