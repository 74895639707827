import { LexicalExportVisitor } from '@mdxeditor/editor';
import { $isBibleLinkNode, BibleLink, BibleLinkNode } from './BibleLinkNode.js';

export const BibleLinkVisitor: LexicalExportVisitor<BibleLinkNode, BibleLink> = {
  testLexicalNode: $isBibleLinkNode,
  visitLexicalNode({ actions, lexicalNode }) {
    actions.addAndStepInto('bibleLink', {
      reference: lexicalNode.getReference(),
      renderedText: lexicalNode.getRenderedText(),
      bibleVersion: lexicalNode.getBibleVersion(),
      flags: lexicalNode.getFlags(),
    });
  },
};
