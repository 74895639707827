import React, { useEffect, useState } from 'react';
import { SnapshotCard } from './SnapshotCard.js';
import { SnapshotPlaceholderList } from './helpers/SnapshotPlaceholder.js';
import { useTranslation } from 'react-i18next';
import { BSWindowSize } from '../../hooks/WindowSize.js';
import { useGetAllPrayerItemsQuery, useLazyGetAllPrayerItemsQuery } from '../../services/PrayerService.js';
import { useUserSetting } from '../../hooks/Setting.js';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { ResponsiveMasonry, Masonry } from '../common/ui/masonry/index.js';
import { ErrorLoadingDataMessage } from '@soulhx/ui-common';

export const PrayerSnapshot = () => {
  const { t } = useTranslation(['pray']);
  const { settingValue: sortOrder } = useUserSetting<string>('prayerSort');
  const [getMorePrayers] = useLazyGetAllPrayerItemsQuery();
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(false);

  useEffect(() => {
    getMorePrayers({ sortOrder, showCompleted: false, filterEmptyLists: true, page: 2 })
      .unwrap()
      .then((response) => {
        if (response.length > 0) setHasMoreItems(true);
      });
  }, [sortOrder, setHasMoreItems, getMorePrayers]);

  const {
    data: prayers,
    isLoading: prayerLoading,
    error: prayerError,
  } = useGetAllPrayerItemsQuery({
    sortOrder,
    showCompleted: false,
    filterEmptyLists: true,
  });

  if (prayerLoading) {
    return <SnapshotPlaceholderList />;
  }
  if (prayerError) {
    return <ErrorLoadingDataMessage errors={[prayerError]} />;
  }

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <h5 className="text-center">{t('pray:snapshotTitle')}</h5>
        </Col>
      </Row>

      <Row>
        <Col xs="12" className="p-0">
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              [BSWindowSize.XS]: 1,
              [BSWindowSize.SM]: 2,
              [BSWindowSize.MD]: 2,
              [BSWindowSize.LG]: 2,
              [BSWindowSize.XL]: 3,
              [BSWindowSize.XXL]: 4,
            }}
          >
            <Masonry>
              {prayers?.map((item) => (
                <SnapshotCard key={item.id} item={item} />
              ))}
              {hasMoreItems && (
                <Alert variant="light">
                  <Alert.Link href="/pray">{t('pray:seeAllItems')}</Alert.Link>
                </Alert>
              )}
              {prayers!.length < 1 && <Alert variant="light">{t('pray:noItems')}</Alert>}
            </Masonry>
          </ResponsiveMasonry>
        </Col>
      </Row>
    </Container>
  );
};
