import React from 'react';
import { IIconProps } from '../../dm/icons.js';

export const BibleIcon = ({ color, size, className }: IIconProps) => {
  return (
    <svg
      version="1.1"
      id="_x32_"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      height={size}
      width={size}
      className={className}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <polygon fill={color} points="481.771,313.228 257.896,430.587 261.287,437.071 485.162,319.697 "></polygon>
          <polygon fill={color} points="254.896,469.806 486.053,348.603 482.662,342.119 251.506,463.337 "></polygon>
          <path
            fill={color}
            d="M486.193,364.541L239.209,493.962c-7.5,1.906-5.781-3.953-5.797-13.125 c0.688-24.656,6.719-46.203,13.391-53.25L33.021,142.525c-9.563,11.453-17.781,47.094-10.969,85.891l211.125,281.515 c1.547,2.031,4.328,2.656,6.578,1.469l247.875-131.312c2.203-1.172,3.719-3.328,4.063-5.797s-0.516-4.953-2.328-6.688 L486.193,364.541z"
          ></path>
          <path
            fill={color}
            d="M489.85,269.259L291.803,5.181c-3.813-5.078-10.766-6.656-16.391-3.688L45.803,122.431L259.6,407.494 l225.938-119.016c3.359-1.766,5.766-4.906,6.609-8.609C492.959,276.166,492.146,272.291,489.85,269.259z M310.818,280.572 l-62.516-83.359l-53.5,28.172l-24.25-32.328l53.5-28.172l-36.594-48.797l40.031-21.078l36.594,48.781l44.594-23.484l24.25,32.328 l-44.594,23.5l62.531,83.344L310.818,280.572z"
          ></path>
        </g>
      </g>
    </svg>
  );
};
