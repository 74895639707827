import {
  activeEditor$,
  Action,
  Cell,
  createActiveEditorSubscription$,
  currentSelection$,
  getSelectedNode,
  getSelectionRectangle,
  IS_APPLE,
  readOnly$,
  Signal,
  withLatestFrom,
  realmPlugin,
  addComposerChild$,
} from '@mdxeditor/editor';
import { filter, map } from '@mdxeditor/gurx';
import { $createBibleLinkNode, $isBibleLinkNode } from '../bl-plugin/BibleLinkNode.js';
import {
  $createTextNode,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  KEY_MODIFIER_COMMAND,
  RangeSelection,
} from 'lexical';
import { isRefValid } from '@soulhx/fs-common';
import { BibleLinkDialog } from './BibleLinkDialog.js';

export type RectData = Pick<DOMRect, 'height' | 'width' | 'top' | 'left'>;

export interface InactiveBibleLinkDialog {
  type: 'inactive';
  rectangle?: undefined;
  bibleLinkNodeKey?: undefined;
}

export interface PreviewBibleLinkDialog {
  type: 'preview';
  reference: string;
  renderedText?: string;
  bibleVersion?: string;
  simplified?: boolean;
  hideVersion?: boolean;
  bibleLinkNodeKey: string;
  rectangle: RectData;
}

export interface EditBibleLinkDialog {
  type: 'edit';
  initialReference: string;
  initialRenderedText?: string;
  initialBibleVersion?: string;
  initialSimplified?: boolean;
  initialHideVersion?: boolean;
  reference: string;
  renderedText?: string;
  bibleVersion?: string;
  simplified?: boolean;
  hideVersion?: boolean;
  bibleLinkNodeKey: string;
  rectangle: RectData;
}

function getBibleLinkNodeInSelection(selection: RangeSelection | null) {
  if (!selection) return null;

  const node = getSelectedNode(selection);
  if (node === null) return null;

  const parent = node.getParent();
  if ($isBibleLinkNode(parent)) {
    return parent;
  } else if ($isBibleLinkNode(node)) {
    return node;
  }

  return null;
}

export const onWindowChange$ = Signal<true>();

export const bibleLinkDialogState$ = Cell<InactiveBibleLinkDialog | PreviewBibleLinkDialog | EditBibleLinkDialog>(
  { type: 'inactive' },
  (r) => {
    r.pub(createActiveEditorSubscription$, (editor) => {
      return editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          const state = r.getValue(bibleLinkDialogState$);
          if (state.type === 'preview') {
            r.pub(bibleLinkDialogState$, { type: 'inactive' });
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      );
    });

    r.pub(createActiveEditorSubscription$, (editor) => {
      return editor.registerCommand(
        KEY_MODIFIER_COMMAND,
        (event) => {
          if (
            event.key === 's' &&
            (IS_APPLE ? event.metaKey : event.ctrlKey) &&
            event.shiftKey &&
            !r.getValue(readOnly$)
          ) {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              r.pub(openBibleLinkEditDialog$);
              event.stopPropagation();
              event.preventDefault();
              return true;
            } else {
              return false;
            }
          }

          return false;
        },
        COMMAND_PRIORITY_HIGH
      );
    });

    r.link(
      r.pipe(
        switchFromPreviewToBibleLinkEdit$,
        withLatestFrom(bibleLinkDialogState$),
        map(([, state]) => {
          if (state.type === 'preview') {
            return {
              type: 'edit' as const,
              initialReference: state.reference,
              reference: state.reference,
              initialRenderedText: state.renderedText,
              renderedText: state.renderedText,
              initialBibleVersion: state.bibleVersion,
              bibleVersion: state.bibleVersion,
              initialHideVersion: state.hideVersion,
              hideVersion: state.hideVersion,
              initialSimplified: state.simplified,
              simplified: state.simplified,
              bibleLinkNodeKey: state.bibleLinkNodeKey,
              rectangle: state.rectangle,
            } as EditBibleLinkDialog;
          } else {
            throw new Error('Cannot switch to edit mode when not in preview mode');
          }
        })
      ),
      bibleLinkDialogState$
    );

    r.sub(
      r.pipe(removeBibleLink$, withLatestFrom(activeEditor$, currentSelection$)),
      ([payload, editor, selection]) => {
        const reference = payload.reference?.trim() ?? '';
        const renderedText = payload.renderedText?.trim() ?? '';

        const newText = renderedText.length > 0 ? renderedText : reference;

        editor?.update(() => {
          const bibleLinkNode = getBibleLinkNodeInSelection(selection);
          if (!bibleLinkNode) return;

          const textNode = $createTextNode(newText);
          bibleLinkNode.insertAfter(textNode);
          bibleLinkNode.select();
          bibleLinkNode.remove();
        });
      }
    );

    r.sub(
      r.pipe(updateBibleLink$, withLatestFrom(activeEditor$, bibleLinkDialogState$, currentSelection$)),
      ([payload, editor, state, selection]) => {
        const reference = payload.reference?.trim() ?? '';
        const renderedText = payload.renderedText?.trim() ?? '';
        const bibleVersion = payload.bibleVersion?.trim() ?? '';
        const flags = payload.flags?.trim() ?? '';
        const simplified = flags.includes('s');
        const hideVersion = flags.includes('h');

        if (reference !== '') {
          editor?.update(
            () => {
              const bibleLinkNode = getBibleLinkNodeInSelection(selection);
              if (!bibleLinkNode) {
                const node = $createBibleLinkNode(reference, { bibleVersion, renderedText, flags });
                $insertNodes([node]);
                node.select();
              } else {
                bibleLinkNode.setReference(reference);
                bibleLinkNode.setRenderedText(renderedText);
                bibleLinkNode.setBibleVersion(bibleVersion);
                bibleLinkNode.setFlags(flags);
              }
            },
            { discrete: true }
          );

          r.pub(bibleLinkDialogState$, {
            type: 'preview',
            bibleLinkNodeKey: state.bibleLinkNodeKey,
            rectangle: state.rectangle,
            reference,
            renderedText,
            bibleVersion,
            simplified,
            hideVersion,
          } as PreviewBibleLinkDialog);
        } else {
          if (state.type === 'edit' && state.reference !== '') {
            //TODO:
            // editor?.dispatchCommand(TOGGLE_BIBLELINK_COMMAND, null);
          }
          r.pub(bibleLinkDialogState$, {
            type: 'inactive',
          });
        }
      }
    );

    r.link(
      r.pipe(
        cancelBibleLinkEdit$,
        withLatestFrom(bibleLinkDialogState$, activeEditor$),
        map(([, state, editor]) => {
          if (state.type === 'edit') {
            editor?.focus();
            if (state.initialReference === '') {
              return { type: 'inactive' as const } as InactiveBibleLinkDialog;
            } else {
              return {
                type: 'preview' as const,
                reference: state.reference,
                renderedText: state.renderedText,
                bibleVersion: state.bibleVersion,
                hideVersion: state.hideVersion,
                simplified: state.simplified,
                bibleLinkNodeKey: state.bibleLinkNodeKey,
                rectangle: state.rectangle,
              } as PreviewBibleLinkDialog;
            }
          } else {
            return { type: 'inactive' } as InactiveBibleLinkDialog;
          }
        })
      ),
      bibleLinkDialogState$
    );

    r.link(
      r.pipe(
        r.combine(currentSelection$, onWindowChange$),
        withLatestFrom(activeEditor$, bibleLinkDialogState$, readOnly$),
        map(([[selection], activeEditor, , readOnly]) => {
          if ($isRangeSelection(selection) && activeEditor && !readOnly) {
            const node = getBibleLinkNodeInSelection(selection);

            if (node) {
              const flags = node.getFlags();
              const hideVersion = flags && flags.includes('h');
              const simplified = flags && flags.includes('s');

              return {
                type: 'preview',
                reference: node.getReference(),
                renderedText: node.getRenderedText(),
                bibleVersion: node.getBibleVersion(),
                simplified: simplified,
                hideVersion: hideVersion,
                bibleLinkNodeKey: node.getKey(),
                rectangle: getSelectionRectangle(activeEditor),
              } as PreviewBibleLinkDialog;
            } else {
              return { type: 'inactive' } as InactiveBibleLinkDialog;
            }
          } else {
            return { type: 'inactive' } as InactiveBibleLinkDialog;
          }
        })
      ),
      bibleLinkDialogState$
    );
  }
);

export const updateBibleLink$ = Signal<{
  reference: string | undefined;
  renderedText: string | undefined;
  bibleVersion: string | undefined;
  flags: string | undefined;
}>();

export const removeBibleLink$ = Signal<{
  reference: string | undefined;
  renderedText: string | undefined;
}>();

export const cancelBibleLinkEdit$ = Action();

export const applyBibleLinkChanges$ = Action();

export const switchFromPreviewToBibleLinkEdit$ = Action();

export const openBibleLinkEditDialog$ = Action((r) => {
  r.sub(
    r.pipe(
      openBibleLinkEditDialog$,
      withLatestFrom(currentSelection$, activeEditor$),
      filter(([, selection]) => $isRangeSelection(selection))
    ),
    ([, selection, editor]) => {
      editor?.focus(() => {
        editor.getEditorState().read(() => {
          const node = getBibleLinkNodeInSelection(selection);
          const selectedText = selection?.getTextContent();

          const rectangle = getSelectionRectangle(editor)!;
          if (node) {
            const flags = node.getFlags();
            const simplified = flags !== undefined && flags.includes('s');
            const hideVersion = flags !== undefined && flags.includes('h');

            r.pub(bibleLinkDialogState$, {
              type: 'edit',
              initialReference: node.getReference(),
              reference: node.getReference(),
              initialRenderedText: node.getRenderedText(),
              renderedText: node.getRenderedText(),
              initialBibleVersion: node.getBibleVersion(),
              bibleVersion: node.getBibleVersion(),
              initialSimplified: simplified,
              simplified: simplified,
              initialHideVersion: hideVersion,
              hideVersion: hideVersion,
              bibleLinkNodeKey: node.getKey(),
              rectangle,
            });
          } else {
            const validRef = isRefValid(selectedText);
            r.pub(bibleLinkDialogState$, {
              type: 'edit',
              initialReference: validRef ? selectedText! : '',
              reference: validRef ? selectedText! : '',
              initialRenderedText: !validRef && selectedText ? selectedText : undefined,
              renderedText: !validRef && selectedText ? selectedText : undefined,
              initialSimplified: false,
              simplified: false,
              initialHideVersion: false,
              hideVersion: false,
              bibleLinkNodeKey: '',
              rectangle,
            });
          }
        });
      });
    }
  );
});

export type ClickBibleLinkCallback = (reference: string) => void;
export const onClickBibleLinkCallback$ = Cell<ClickBibleLinkCallback | null>(null);

export const bibleLinkDialogPlugin = realmPlugin<{
  /**
   * If set, clicking on the link in the preview popup will call this callback instead of opening the link.
   */
  onClickBibleLinkCallback?: ClickBibleLinkCallback;
  defaultVersion?: string;
  passageContext?: string;
}>({
  init(r, params) {
    r.pub(addComposerChild$, BibleLinkDialog);
    r.pub(onClickBibleLinkCallback$, params?.onClickBibleLinkCallback ?? null);
  },
});
