import { ElementNode, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical';
import { Parent, Node, PhrasingContent } from 'mdast';

export interface Sup extends Parent, Node {
  type: 'sup';
  children: PhrasingContent[];
}

export type SerializedSubNode = Spread<
  {
    mdastNode: Sup;
  },
  SerializedLexicalNode
>;

export class SupNode extends ElementNode {
  __mdastNode: Sup;

  static getType() {
    return 'sup';
  }

  static clone(node: SupNode): SupNode {
    return new SupNode(structuredClone(node.__mdastNode), node.__key);
  }

  static importJSON(serializedNode: SerializedSubNode): SupNode {
    return $createSupNode(serializedNode.mdastNode);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'sup',
    };
  }

  getMdastNode() {
    return this.__mdastNode;
  }

  constructor(mdastNode?: Sup, key?: NodeKey) {
    super(key);
    this.__mdastNode = mdastNode ?? { type: 'sup', children: [] };
  }

  createDOM() {
    return document.createElement('sup');
  }

  updateDOM() {
    return false;
  }

  isInline() {
    return true;
  }
}

export function $isSupNode(node: LexicalNode | null | undefined): node is SupNode {
  return node instanceof SupNode;
}

export function $createSupNode(mdastNode: Sup): SupNode {
  return new SupNode(mdastNode);
}
