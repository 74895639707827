import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * Standard inline loading message
 */
export const LoadingMessage = () => {
  const { t } = useTranslation();

  return <Alert variant="primary">{t('loadingMessage.loading')}</Alert>;
};

interface ILoadingModal {
  show: boolean;
}
/**
 * Standard loading message displayed as a modal over the current
 * UI, for long-running processes that happen after a page has
 * loaded.
 */
export const LoadingModal = ({ show }: ILoadingModal) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Body>{t('loadingMessage.working')}</Modal.Body>
    </Modal>
  );
};
