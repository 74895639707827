import { LexicalExportVisitor } from '@mdxeditor/editor';
import { $isSmallCapsNode, SmallCaps, SmallCapsNode } from './nodes/SmallCapsNode.js';
import { $isMarkNode, Mark, MarkNode } from './nodes/MarkNode.js';
import { $isEraNode, Era, EraNode } from './nodes/EraNode.js';
import { $isSupNode, Sup, SupNode } from './nodes/SuperscriptNode.js';
import { $isAllUpperNode, AllUpper, AllUpperNode } from './nodes/AllUpperNode.js';

export const LexicalSmallCapsVisitor: LexicalExportVisitor<SmallCapsNode, SmallCaps> = {
  testLexicalNode: $isSmallCapsNode,
  visitLexicalNode({ actions }) {
    actions.addAndStepInto('smallcaps', { type: 'smallcaps', children: [] });
  },
};

export const LexicalMarkVisitor: LexicalExportVisitor<MarkNode, Mark> = {
  testLexicalNode: $isMarkNode,
  visitLexicalNode({ actions }) {
    actions.addAndStepInto('mark', { type: 'mark', children: [] });
  },
};

export const LexicalEraVisitor: LexicalExportVisitor<EraNode, Era> = {
  testLexicalNode: $isEraNode,
  visitLexicalNode({ actions }) {
    actions.addAndStepInto('era', { type: 'era', children: [] });
  },
};

export const LexicalSupVisitor: LexicalExportVisitor<SupNode, Sup> = {
  testLexicalNode: $isSupNode,
  visitLexicalNode({ actions }) {
    actions.addAndStepInto('sup', { type: 'sup', children: [] });
  },
};

export const LexicalAllUpperVisitor: LexicalExportVisitor<AllUpperNode, AllUpper> = {
  testLexicalNode: $isAllUpperNode,
  visitLexicalNode({ actions, lexicalNode }) {
    actions.addAndStepInto('allupper', { type: 'allupper', children: [], theText: lexicalNode.getTheText() });
  },
};
