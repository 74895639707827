import { $createTextNode, ElementNode, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical';
import { getBGUrl } from '../helpers/get-url.js';
import { Node, Parent } from 'mdast';
import { getFormattedRef } from '@soulhx/fs-common';

export interface BibleLink extends Parent, Node {
  type: 'bibleLink';
  reference: string;
  renderedText?: string;
  bibleVersion?: string;
  flags?: string;
}

export type BibleLinkAttrs = {
  renderedText?: string;
  bibleVersion?: string;
  flags?: string;
};

export type SerializedBibleLinkNode = Spread<
  {
    reference: string;
  },
  Spread<BibleLinkAttrs, SerializedLexicalNode>
>;

export class BibleLinkNode extends ElementNode {
  __reference: string;
  __renderedText?: string;
  __bibleVersion?: string;
  __flags?: string;

  constructor(reference: string, { renderedText, bibleVersion, flags }: BibleLinkAttrs = {}, key?: NodeKey) {
    super(key);
    this.__reference = reference;
    this.__renderedText = renderedText;
    this.__bibleVersion = bibleVersion;
    this.__flags = flags;
  }

  static getType(): string {
    return 'bibleLink';
  }

  static clone(node: BibleLinkNode): BibleLinkNode {
    return new BibleLinkNode(
      node.__reference,
      {
        renderedText: node.__renderedText,
        bibleVersion: node.__bibleVersion,
        flags: node.__flags,
      },
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedBibleLinkNode): BibleLinkNode {
    return $createBibleLinkNode(serializedNode.reference, {
      renderedText: serializedNode.renderedText,
      bibleVersion: serializedNode.bibleVersion,
      flags: serializedNode.flags,
    });
  }

  exportJSON() {
    return super.exportJSON();
  }

  getReference(): string {
    return this.__reference;
  }

  setReference(reference: string) {
    const writable = this.getWritable();
    writable.__reference = reference;
  }

  getRenderedText(): string | undefined {
    return this.__renderedText;
  }

  setRenderedText(renderedText: string | undefined) {
    const writable = this.getWritable();
    writable.__renderedText = renderedText;
  }

  getBibleVersion(): string | undefined {
    return this.__bibleVersion;
  }

  setBibleVersion(bibleVersion: string | undefined) {
    const writable = this.getWritable();
    writable.__bibleVersion = bibleVersion;
  }

  getFlags(): string | undefined {
    return this.__flags;
  }

  setFlags(flags: string | undefined) {
    const writable = this.getWritable();
    writable.__flags = flags;
  }

  updateDOM(): false {
    return false;
  }

  //TODO: includeVerses,context
  createDOM(): HTMLElement {
    const hideVersion = this.__flags && this.__flags.includes('h');

    const linkUrl = getBGUrl(this.__reference, { version: this.__bibleVersion });

    const element = document.createElement('a');
    element.href = linkUrl;
    element.target = '_blank';
    let title = this.__reference;
    if (this.__bibleVersion && !hideVersion) {
      title += ' (' + this.__bibleVersion + ')';
    }
    element.setAttribute('title', title);

    return element;
  }

  isInline(): true {
    return true;
  }
}

export function $isBibleLinkNode(node: LexicalNode | null | undefined): node is BibleLinkNode {
  return node instanceof BibleLinkNode;
}

export function $createBibleLinkNode(reference: string, attributes: BibleLinkAttrs): BibleLinkNode {
  const simplified = attributes.flags && attributes.flags.includes('s');
  const hideVersion = attributes.flags && attributes.flags.includes('h');

  let toDisplay = simplified
    ? attributes.renderedText || reference
    : attributes.renderedText || getFormattedRef(reference);
  toDisplay = toDisplay.replace(/([0-9])-([0-9])/g, '$1–$2');
  if (attributes.bibleVersion && !hideVersion) {
    toDisplay += ' (' + attributes.bibleVersion + ')';
  }
  toDisplay += '✞';

  return new BibleLinkNode(reference, attributes).append($createTextNode(toDisplay));
}
