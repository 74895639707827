import { isRefValid } from '@soulhx/fs-common';
import { Code, Effects, State } from 'micromark-util-types';

declare module 'micromark-util-types' {
  interface TokenTypeMap {
    bibleLink: 'bibleLink';
    bibleLinkMarker: 'bibleLinkMarker';
    reference: 'reference';
    renderedText: 'renderedText';
    bibleVersion: 'bibleVersion';
    flags: 'flags';
    hideChrome: 'hideChrome';
    simplified: 'simplified';
  }
}

const codes = {
  OPEN_SQUARE: 91,
  CLOSE_SQUARE: 93,
  OPEN_PAREN: 40,
  CLOSE_PAREN: 41,
  PIPE: 124,
  SEMI_COLON: 59,
  SIMPLIFIED_FLAG: 115,
  HIDE_VERSION_FLAG: 104,
};

export function shxBibleLink() {
  const startMarker = '[|';
  const startRenderedMarker = '(';
  const endRenderedTextMarker = ')|';
  const versionMarker = '|';

  function tokenize(effects: Effects, ok: State, nok: State) {
    let reference = '';
    let referenceFound = false;
    let renderedTextFound = false;
    let versionConsumed = false;
    let flagsFound = false;
    let startMarkerCursor = 0;
    let renderedTextCursor = 0;
    let endRenderedTextCursor = 0;
    let versionMarkerCursor = 0;

    return start;

    function start(code: Code) {
      if (code !== startMarker.charCodeAt(startMarkerCursor)) return nok(code);

      effects.enter('bibleLink');
      effects.enter('bibleLinkMarker');

      return consumeStart(code);
    }

    function consumeStart(code: Code) {
      if (startMarkerCursor === startMarker.length) {
        effects.exit('bibleLinkMarker');
        effects.enter('reference');
        return consumeRef(code);
      }

      if (code !== startMarker.charCodeAt(startMarkerCursor)) {
        return nok(code);
      }

      effects.consume(code);
      startMarkerCursor++;

      return consumeStart;
    }

    function consumeRef(code: Code) {
      if (code === codes.OPEN_PAREN) {
        if (!referenceFound) return nok(code);
        effects.exit('reference');
        effects.enter('bibleLinkMarker');
        return consumeRenderedTextMarker(code);
      }

      if (code === codes.PIPE) {
        if (!referenceFound) return nok(code);
        effects.exit('reference');
        effects.enter('bibleLinkMarker');
        return consumeVersionMarker(code);
      }

      referenceFound = true;
      reference += String.fromCharCode(code!);
      effects.consume(code);
      return consumeRef;
    }

    function consumeRenderedTextMarker(code: Code) {
      if (renderedTextCursor === startRenderedMarker.length) {
        effects.exit('bibleLinkMarker');
        effects.enter('renderedText');
        return consumeRenderedText(code);
      }

      if (code !== codes.OPEN_PAREN) return nok(code);

      effects.consume(code);
      renderedTextCursor++;

      return consumeRenderedTextMarker;
    }

    function consumeRenderedText(code: Code) {
      if (code === endRenderedTextMarker.charCodeAt(endRenderedTextCursor)) {
        if (!renderedTextFound) return nok(code);
        effects.exit('renderedText');
        effects.enter('bibleLinkMarker');
        return consumeRenderedTextEnd(code);
      }

      renderedTextFound = true;
      effects.consume(code);
      return consumeRenderedText;
    }

    function consumeRenderedTextEnd(code: Code) {
      if (endRenderedTextCursor === endRenderedTextMarker.length) {
        effects.exit('bibleLinkMarker');
        return consumeVersion(code);
      }

      if (code !== endRenderedTextMarker.charCodeAt(endRenderedTextCursor)) return nok(code);

      effects.consume(code);
      endRenderedTextCursor++;
      return consumeRenderedTextEnd;
    }

    function consumeVersionMarker(code: Code) {
      if (versionMarkerCursor === versionMarker.length) {
        effects.exit('bibleLinkMarker');
        return consumeVersion(code);
      }

      if (code !== versionMarker.charCodeAt(versionMarkerCursor)) return nok(code);

      effects.consume(code);
      versionMarkerCursor++;
      return consumeVersionMarker;
    }

    function consumeVersion(code: Code) {
      if (code === codes.SEMI_COLON) {
        if (versionConsumed) {
          effects.exit('bibleVersion');
        }
        effects.enter('flags');
        return consumeFlags(code);
      }

      if (code === codes.CLOSE_SQUARE) {
        if (versionConsumed) {
          effects.exit('bibleVersion');
        }
        effects.enter('bibleLinkMarker');
        return consumeEnd(code);
      }

      if (!versionConsumed) {
        versionConsumed = true;
        effects.enter('bibleVersion');
      }
      effects.consume(code);
      return consumeVersion;
    }

    function consumeFlags(code: Code) {
      if (code === codes.CLOSE_SQUARE) {
        if (!flagsFound) return nok(code);
        effects.exit('flags');
        effects.enter('bibleLinkMarker');
        return consumeEnd(code);
      }

      flagsFound = true;

      if (code === codes.HIDE_VERSION_FLAG) {
        effects.enter('hideChrome');
        effects.consume(code);
        effects.exit('hideChrome');
      } else if (code === codes.SIMPLIFIED_FLAG) {
        effects.enter('simplified');
        effects.consume(code);
        effects.exit('simplified');
      } else {
        effects.consume(code);
      }

      return consumeFlags;
    }

    function consumeEnd(code: Code) {
      if (!isRefValid(reference)) return nok(code);
      effects.consume(code);
      effects.exit('bibleLinkMarker');
      effects.exit('bibleLink');
      return ok(code);
    }
  }

  return {
    text: {
      [codes.OPEN_SQUARE]: {
        tokenize: tokenize,
      },
    },
  };
}
