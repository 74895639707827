import { LexicalExportVisitor } from '@mdxeditor/editor';
import { $isFootnoteDefinitionNode, FootnoteDefinitionNode } from './nodes/FootnoteDefinitionNode.js';
import { $isFootnoteReferenceNode, FootnoteReferenceNode } from './nodes/FootnoteReferenceNode.js';
import { FootnoteDefinition, FootnoteReference } from 'mdast';

export const LexicalFootnoteDefinitionVisitor: LexicalExportVisitor<FootnoteDefinitionNode, FootnoteDefinition> = {
  testLexicalNode: $isFootnoteDefinitionNode,
  visitLexicalNode({ actions, lexicalNode }) {
    actions.addAndStepInto('footnoteDefinition', {
      identifier: lexicalNode.getIdentifier(),
      label: lexicalNode.getLabel(),
    });
  },
};

export const LexicalFootnoteReferenceVisitor: LexicalExportVisitor<FootnoteReferenceNode, FootnoteReference> = {
  testLexicalNode: $isFootnoteReferenceNode,
  visitLexicalNode({ actions, lexicalNode }) {
    actions.addAndStepInto('footnoteReference', {
      identifier: lexicalNode.getIdentifier(),
      label: lexicalNode.getLabel(),
    });
  },
};
