import React from 'react';
import { Trans } from 'react-i18next';

interface IChildrenWithOtherContent {
  text?: string;
  icon: JSX.Element;
  i18nKey?: string;
}
export const ChildrenWithOtherContent = ({ text, icon, i18nKey }: IChildrenWithOtherContent) => {
  if (text) {
    return (
      <Trans
        i18nKey={i18nKey || 'commonComponents.buttons.childrenWithContent'}
        values={{ content: text }}
        components={{ icon: icon }}
      />
    );
  }

  return icon;
};
