import React, { useState } from 'react';
import { ActionEntry } from '@soulhx/fs-common';
import { DateTime } from 'luxon';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDeleteActionEntryMutation, useUpdateActionEntryMutation } from '../../services/ActionsService.js';
import { StandardButton } from '@soulhx/ui-common';
import { useTranslation } from 'react-i18next';

const updatedDate = (oldDate: DateTime, newHour?: string, newMinute?: string): DateTime => {
  if (!newHour && !newMinute) {
    return oldDate;
  }

  const newDate = DateTime.fromObject({
    year: oldDate.year,
    month: oldDate.month,
    day: oldDate.day,
    hour: newHour ? Number(newHour) : oldDate.hour,
    minute: newMinute ? Number(newMinute) : oldDate.minute,
  });

  return newDate;
};

interface ICompletedItem {
  item: ActionEntry;
}
export const CompletedItem = ({ item }: ICompletedItem) => {
  const [modalShowing, setModalShowing] = useState<boolean>(false);
  const [itemDate, setItemDate] = useState<DateTime>(DateTime.fromISO(item.entryDate));
  const [deleteEntry] = useDeleteActionEntryMutation();
  const [updateEntry] = useUpdateActionEntryMutation();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { t } = useTranslation(['do']);

  return (
    <>
      <li className="mb-2">
        {itemDate.toLocaleString(DateTime.TIME_SIMPLE)}
        <StandardButton
          type="edit"
          variant="outline-secondary"
          className="my-0 mx-3 p-0"
          onClick={() => setModalShowing(true)}
          itemType={t('do:itemType')}
        />

        <StandardButton
          type="delete"
          itemType={t('do:itemType')}
          className="my-0 mx-3 p-0"
          onClick={() => {
            deleteEntry(item.id)
              .unwrap()
              .then(() => {
                setIsDirty(false);
              });
          }}
        />
      </li>

      <Modal show={modalShowing} onHide={() => setModalShowing(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('do:editAction.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('do:editAction.timeLabel')}</Form.Label>
            <Form.Control
              type="time"
              value={itemDate.toLocaleString(DateTime.TIME_24_SIMPLE)}
              onChange={(e) => {
                setIsDirty(true);
                const parts = e.currentTarget.value.split(':');
                const newDate = updatedDate(itemDate, parts[0], parts[1]);
                setItemDate(newDate);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {isDirty && (
            <Button
              onClick={() => {
                updateEntry({ id: item.id, entryDate: itemDate.toISO()! })
                  .unwrap()
                  .then(() => {
                    setIsDirty(false);
                    setModalShowing(false);
                  });
              }}
            >
              {t('do:editAction.saveBtn')}
            </Button>
          )}
          <Button onClick={() => setModalShowing(false)}>{t('do:editAction.closeBtn')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
