import React, { ReactNode } from 'react';
import { Accordion } from 'react-bootstrap';

interface ISidebarCollapseWidget {
  title: ReactNode;
  visible: boolean;
  clickFunction: () => void;
  children: ReactNode;
  titleClass?: string;
}
export const SidebarCollapseWidget = ({
  title,
  visible,
  clickFunction,
  children,
  titleClass,
}: ISidebarCollapseWidget) => {
  return (
    <Accordion className={visible ? 'mb-5' : 'mb-3'} defaultActiveKey={visible ? '0' : undefined} flush>
      <Accordion.Item eventKey="0" onClick={clickFunction}>
        <Accordion.Header
          className={`bg-primary-subtle text-primary mb-0 border border-primary-subtle ${
            titleClass ? titleClass : ''
          } ${visible ? 'border-bottom-0' : ''}`}
        >
          {title}
        </Accordion.Header>
        <Accordion.Body className="border border-1 border-primary-subtle">{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
