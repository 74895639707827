import React, { useCallback, useState } from 'react';
import {
  ActionEntry,
  BaseActionEntry,
  BasePassage,
  InstantiatedPlanDay,
  ReadingPlanDay,
  getFormattedRef,
} from '@soulhx/fs-common';
import { DateTime } from 'luxon';
import { Form, ListGroup } from 'react-bootstrap';
import { ShowToastFunction, ToastType } from '../../../hooks/toast/Toasts.js';
import {
  useCreateEntryMutation,
  useGetSpecialTypeIDsQuery,
  useUpdateActionEntryMutation,
} from '../../../services/ActionsService.js';
import { useNewPassageMutation } from '../../../services/PassageService.js';
import {
  CatchupPlan,
  useCatchupInstantiatedPlanMutation,
  useDeleteInstantiatedPlanMutation,
  useUpdateDayForInstantiatedPlanMutation,
} from '../../../services/PlansService.js';
import { StandardButton } from '@soulhx/ui-common';
import { PlanItemStats } from '../helpers/PlanItemStats.js';
import { getOtNtPassageStatsFromVerses, getReadingPlanClass } from '../helpers/helper-functions.js';
import { PlanItemToastBody } from '../helpers/read/PlanItemToastBody.js';
import { useTranslation } from 'react-i18next';
import { OnlineBibleViewer } from '../../read/helpers/OnlineBibleViewer.js';
import { useGetActionEntriesForDay } from '../../../hooks/GetActionEntries.js';
import { handleInit } from '../../../hooks/HandleInit.js';
import { useUserSetting } from '../../../hooks/Setting.js';
import { BibleLink } from '@soulhx/editor';

interface IReadingPlanItem {
  plan: ReadingPlanDay;
  dateToShow: string;
  version: string;
  showToast: ShowToastFunction;
  showUnsub?: boolean;
}
export const ReadingPlanItem = ({ plan, dateToShow, version, showToast, showUnsub = false }: IReadingPlanItem) => {
  const {
    data: specialTypeIds,
    error: specialTypesError,
    isLoading: specialTypesLoading,
  } = useGetSpecialTypeIDsQuery();
  const { settingValue: defaultVersion } = useUserSetting<string>('readDefaultVersion');

  const [newPassage] = useNewPassageMutation();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [updateDay] = useUpdateDayForInstantiatedPlanMutation();
  const { entries, entriesError, entriesLoading } = useGetActionEntriesForDay(
    DateTime.fromISO(dateToShow).year.toString(),
    DateTime.fromISO(dateToShow).month.toString(),
    DateTime.fromISO(dateToShow).day.toString()
  );
  const [updateActionEntry] = useUpdateActionEntryMutation();
  const [createActionEntry] = useCreateEntryMutation();
  const [callCatchupApi] = useCatchupInstantiatedPlanMutation();
  const [showCatchupOptions, setShowCatchupOptions] = useState<boolean>(false);
  const [markPrevCatchupRead, setMarkPrevCatchupRead] = useState<boolean>(true);
  const { t } = useTranslation(['plan']);
  const [unsubApi] = useDeleteInstantiatedPlanMutation();

  const markAEComplete = useCallback(
    async (aeID: string) => {
      if (!entries) {
        return;
      }

      const oldAction: ActionEntry = entries![entries!.findIndex((item) => item.id === aeID)];
      if (oldAction) {
        const toUpdate: ActionEntry = {
          ...oldAction,
          completed: true,
        };
        await updateActionEntry(toUpdate)
          .unwrap()
          .then((response) => {
            showToast({
              content: <PlanItemToastBody entry={response} />,
              type: ToastType.Success,
              duration: 10000,
            });
          });
      } else {
        const toUpdate: BaseActionEntry = {
          actionType: aeID,
          completed: true,
        };
        await createActionEntry(toUpdate)
          .unwrap()
          .then((response) => {
            showToast({
              content: <PlanItemToastBody entry={response} />,
              type: ToastType.Success,
              duration: 10000,
            });
          });
      }
    },
    [entries, updateActionEntry, createActionEntry, showToast]
  );

  const resp = handleInit([specialTypesLoading, entriesLoading], [specialTypesError, entriesError]);
  if (resp) return resp;

  const passage: BasePassage = { version: version, osis: plan.osisForDay || '' };

  const [otVerses, ntVerses] = plan.verses ? getOtNtPassageStatsFromVerses(plan.verses!) : [0, 0];
  const otConditionMet: boolean = otVerses > 10;
  const ntConditionMet: boolean = ntVerses > 10;

  const className = getReadingPlanClass(plan);

  return (
    <ListGroup.Item className={className}>
      <div>
        <div>
          <strong>{plan.planName}:</strong>{' '}
          <BibleLink passage={passage.osis} hideVersion={true} version={version} includeVerses={false} />
        </div>
        {(!plan.dayCompleted || showUnsub) && (
          <PlanItemStats
            otVerses={otVerses}
            ntVerses={ntVerses}
            otConditionMet={otConditionMet}
            ntConditionMet={ntConditionMet}
          />
        )}
        <div>
          <StandardButton
            type="complete"
            itemType={t('plan:itemType')}
            done={plan.dayCompleted}
            onClick={(e) => {
              setShowPreview(false);
              e.currentTarget.blur();

              if (!entries || !specialTypeIds) return;
              const ud: InstantiatedPlanDay = {
                id: plan.ipDayId,
                scheduledDate: plan.date,
                completed: !plan.dayCompleted,
              };
              updateDay(ud)
                .unwrap()
                .then(async (responseDay) => {
                  if (responseDay.completed) {
                    if (ntConditionMet) {
                      await markAEComplete(specialTypeIds!.longNT);
                    }
                    if (otConditionMet) {
                      await markAEComplete(specialTypeIds!.longOT);
                    }
                    if (ntVerses > 0 && !ntConditionMet) {
                      await markAEComplete(specialTypeIds!.shortNT);
                    }
                    if (otVerses > 0 && !otConditionMet) {
                      await markAEComplete(specialTypeIds!.shortOT);
                    }
                  }
                })
                .catch(() => {
                  showToast({
                    title: t('plan:item.toastHeading'),
                    content: t('plan:item.toastErrorMarkingComplete'),
                    type: ToastType.Danger,
                  });
                });
            }}
          />

          <StandardButton
            type="save"
            done={false}
            tooltip={t('plan:item.pinItemTooltip')}
            onClick={() => {
              newPassage(passage);
              showToast({ content: t('plan:item.saved'), type: ToastType.Success });
            }}
          />

          <StandardButton
            type="calendar"
            tooltip={t('plan:item.resetItemTooltip')}
            onClick={() => setShowCatchupOptions(!showCatchupOptions)}
          />

          {showCatchupOptions && (
            <div>
              <Form.Text>{t('plan:item.resetItemHelpText')}</Form.Text>

              <Form.Check
                type="switch"
                checked={markPrevCatchupRead}
                onChange={() => setMarkPrevCatchupRead(!markPrevCatchupRead)}
                label={t('plan:item.markPrevDaysReadLabel')}
              />

              <StandardButton
                type="save"
                tooltip={t('plan:item.resetItemTooltip')}
                onClick={() => {
                  const apiDetails: CatchupPlan = {
                    planId: plan.ipId,
                    params: {
                      markPrevAsRead: markPrevCatchupRead,
                      fromDate: dateToShow,
                    },
                  };

                  callCatchupApi(apiDetails);
                }}
              />
            </div>
          )}

          <StandardButton
            type="openCloseBible"
            done={showPreview}
            onClick={() => setShowPreview(!showPreview)}
            disabled={!plan.osisForDay}
            tooltip={t('plan:item.showViewerTooltip')}
          />

          {showUnsub && (
            <StandardButton
              type="delete"
              variant="outline-secondary"
              onClick={() => {
                unsubApi(plan.ipId);
              }}
            />
          )}
        </div>
        {showPreview && plan.osisForDay && (
          <OnlineBibleViewer
            passage={getFormattedRef(plan.osisForDay!, { includeVerses: false })}
            version={defaultVersion}
          />
        )}
      </div>
    </ListGroup.Item>
  );
};
