import { ElementNode, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical';
import { Parent, Node, PhrasingContent } from 'mdast';

export interface SmallCaps extends Parent, Node {
  type: 'smallcaps';
  children: PhrasingContent[];
}

export type SerializedSmallCapsNode = Spread<
  {
    mdastNode: SmallCaps;
  },
  SerializedLexicalNode
>;

export class SmallCapsNode extends ElementNode {
  __mdastNode: SmallCaps;

  static getType() {
    return 'smallcaps';
  }

  static clone(node: SmallCapsNode): SmallCapsNode {
    return new SmallCapsNode(structuredClone(node.__mdastNode), node.__key);
  }

  static importJSON(serializedNode: SerializedSmallCapsNode): SmallCapsNode {
    return $createSmallCapsNode(serializedNode.mdastNode);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'smallcaps',
    };
  }

  getMdastNode() {
    return this.__mdastNode;
  }

  constructor(mdastNode?: SmallCaps, key?: NodeKey) {
    super(key);
    this.__mdastNode = mdastNode ?? { type: 'smallcaps', children: [] };
  }

  createDOM() {
    const element = document.createElement('span');
    element.style.fontVariant = 'small-caps';
    return element;
  }

  updateDOM() {
    return false;
  }

  isInline() {
    return true;
  }
}

export function $isSmallCapsNode(node: LexicalNode | null | undefined): node is SmallCapsNode {
  return node instanceof SmallCapsNode;
}

export function $createSmallCapsNode(mdastNode: SmallCaps) {
  return new SmallCapsNode(mdastNode);
}
