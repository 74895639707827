import React from 'react';
import { Badge } from 'react-bootstrap';
import { Check, CheckAll } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { TooltipElement } from '@soulhx/ui-common';

interface IPlanItemStats {
  otVerses: number;
  ntVerses: number;
  otConditionMet: boolean;
  ntConditionMet: boolean;
}
export const PlanItemStats = ({ otVerses, ntVerses, otConditionMet, ntConditionMet }: IPlanItemStats) => {
  const { t } = useTranslation(['plan', 'translation']);

  return (
    <div className="item-stats">
      {otVerses > 0 && (
        <span>
          {otConditionMet ? (
            <TooltipElement tooltip={t('plan:item.stats.longOTTT')}>
              <CheckAll />
            </TooltipElement>
          ) : (
            <TooltipElement tooltip={t('plan:item.stats.shortOTTT')}>
              <Check />
            </TooltipElement>
          )}{' '}
          {t('translation:general.otShort')}
          <Badge pill>{otVerses}</Badge>
        </span>
      )}
      {otVerses > 0 && ntVerses > 0 && <span>; </span>}
      {ntVerses > 0 && (
        <span>
          {ntConditionMet ? (
            <TooltipElement tooltip={t('plan:item.stats.longNTTT')}>
              <CheckAll />
            </TooltipElement>
          ) : (
            <TooltipElement tooltip={t('plan:item.stats.shortNTTT')}>
              <Check />
            </TooltipElement>
          )}{' '}
          {t('translation:general.ntShort')}
          <Badge pill>{ntVerses}</Badge>
        </span>
      )}
    </div>
  );
};
