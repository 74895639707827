import {
  addExportVisitor$,
  addImportVisitor$,
  addLexicalNode$,
  addMdastExtension$,
  addSyntaxExtension$,
  addToMarkdownExtension$,
  realmPlugin,
} from '@mdxeditor/editor';
import {
  shxCustomSyntaxExtension,
  shxCustomSyntaxFromMarkdown,
  shxCustomSyntaxToMarkdown,
} from '../custom-extension/customSyntaxExtension.js';
import {
  LexicalAllUpperVisitor,
  LexicalEraVisitor,
  LexicalMarkVisitor,
  LexicalSmallCapsVisitor,
  LexicalSupVisitor,
} from './LexicalVisitors.js';
import {
  MdastAllUpperVisitor,
  MdastEraVisitor,
  MdastMarkVisitor,
  MdastSmallCapsVisitor,
  MdastSupVisitor,
} from './MdastVisitors.js';
import { SmallCapsNode } from './nodes/SmallCapsNode.js';
import { MarkNode } from './nodes/MarkNode.js';
import { EraNode } from './nodes/EraNode.js';
import { SupNode } from './nodes/SuperscriptNode.js';
import { AllUpperNode } from './nodes/AllUpperNode.js';

export const shxTextPlugins = realmPlugin({
  init(r) {
    r.pubIn({
      [addMdastExtension$]: [
        shxCustomSyntaxFromMarkdown('smallcaps'),
        shxCustomSyntaxFromMarkdown('mark'),
        shxCustomSyntaxFromMarkdown('era'),
        shxCustomSyntaxFromMarkdown('sup'),
        shxCustomSyntaxFromMarkdown('allupper', true),
      ],
      [addToMarkdownExtension$]: [
        shxCustomSyntaxToMarkdown('^', 4, 'smallcaps'),
        shxCustomSyntaxToMarkdown('=', 2, 'mark'),
        shxCustomSyntaxToMarkdown('^', 2, 'era'),
        shxCustomSyntaxToMarkdown('^', 1, 'sup'),
        shxCustomSyntaxToMarkdown('^', 3, 'allupper', true),
      ],
      [addLexicalNode$]: [SmallCapsNode, MarkNode, EraNode, SupNode, AllUpperNode],
      [addExportVisitor$]: [
        LexicalSmallCapsVisitor,
        LexicalMarkVisitor,
        LexicalEraVisitor,
        LexicalSupVisitor,
        LexicalAllUpperVisitor,
      ],
      [addImportVisitor$]: [
        MdastSmallCapsVisitor,
        MdastMarkVisitor,
        MdastEraVisitor,
        MdastSupVisitor,
        MdastAllUpperVisitor,
      ],
      [addSyntaxExtension$]: [
        shxCustomSyntaxExtension('^'.charCodeAt(0), 4, 'smallcaps'),
        shxCustomSyntaxExtension('='.charCodeAt(0), 2, 'mark'),
        shxCustomSyntaxExtension('^'.charCodeAt(0), 2, 'era'),
        shxCustomSyntaxExtension('^'.charCodeAt(0), 1, 'sup'),
        shxCustomSyntaxExtension('^'.charCodeAt(0), 3, 'allupper', true),
      ],
    });
  },
});
