import React from 'react';
import { IIconProps } from '../../dm/icons.js';

export const OTIcon = ({ size, color, className }: IIconProps) => {
  return (
    <svg
      fill={color}
      height={size}
      width={size}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      stroke={color}
      strokeWidth="10.24"
      className={className}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M503.338,377.006c4.785,0,8.662-3.878,8.662-8.662v-162.21c0-72.967-59.363-132.331-132.331-132.331 c-56.392,0-104.65,35.462-123.669,85.252c-19.018-49.792-67.276-85.252-123.668-85.252C59.363,73.802,0,133.167,0,206.134v25.921 c0,4.784,3.878,8.662,8.662,8.662c4.784,0,8.662-3.878,8.662-8.662v-25.921c0-63.415,51.592-115.007,115.007-115.007 s115.006,51.591,115.006,115.007v214.739H17.325V260.93c0-4.784-3.878-8.662-8.662-8.662c-4.784,0-8.662,3.878-8.662,8.662 v168.605c0,4.784,3.878,8.662,8.662,8.662h494.675c4.785,0,8.662-3.878,8.662-8.662v-32.317c0-4.784-3.877-8.662-8.662-8.662 c-4.785,0-8.662,3.878-8.662,8.662v23.654H264.663V206.134c0-63.415,51.591-115.007,115.006-115.007 s115.007,51.591,115.007,115.007v162.21C494.675,373.128,498.554,377.006,503.338,377.006z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M457.053,196.408H302.284c-4.785,0-8.662,3.878-8.662,8.662c0,4.784,3.877,8.662,8.662,8.662h154.768 c4.785,0,8.662-3.878,8.662-8.662C465.715,200.286,461.838,196.408,457.053,196.408z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M457.053,277.065H302.284c-4.785,0-8.662,3.878-8.662,8.662c0,4.784,3.877,8.662,8.662,8.662h154.768 c4.785,0,8.662-3.878,8.662-8.662C465.715,280.944,461.838,277.065,457.053,277.065z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M457.053,236.737H367.65c-4.785,0-8.662,3.878-8.662,8.662c0,4.784,3.877,8.662,8.662,8.662h89.403 c4.785,0,8.662-3.878,8.662-8.662C465.715,240.615,461.838,236.737,457.053,236.737z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M338.775,236.737h-36.491c-4.785,0-8.662,3.878-8.662,8.662c0,4.784,3.878,8.662,8.662,8.662h36.491 c4.785,0,8.662-3.878,8.662-8.662C347.437,240.615,343.56,236.737,338.775,236.737z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M457.053,317.393h-27.421c-4.785,0-8.662,3.878-8.662,8.662s3.877,8.662,8.662,8.662h27.421 c4.785,0,8.662-3.878,8.662-8.662S461.838,317.393,457.053,317.393z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M400.757,317.393h-98.473c-4.785,0-8.662,3.878-8.662,8.662s3.878,8.662,8.662,8.662h98.473 c4.785,0,8.662-3.878,8.662-8.662S405.543,317.393,400.757,317.393z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M457.053,357.721H302.284c-4.785,0-8.662,3.878-8.662,8.662s3.878,8.662,8.662,8.662h154.768 c4.785,0,8.662-3.878,8.662-8.662S461.838,357.721,457.053,357.721z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M209.714,196.408h-99.243c-4.784,0-8.662,3.878-8.662,8.662c0,4.784,3.878,8.662,8.662,8.662h99.243 c4.784,0,8.662-3.878,8.662-8.662C218.377,200.286,214.498,196.408,209.714,196.408z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M81.598,196.408h-26.65c-4.784,0-8.662,3.878-8.662,8.662c0,4.784,3.878,8.662,8.662,8.662h26.65 c4.784,0,8.662-3.878,8.662-8.662C90.26,200.286,86.381,196.408,81.598,196.408z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M209.716,277.065h-45.344c-4.784,0-8.662,3.878-8.662,8.662c0,4.784,3.878,8.662,8.662,8.662h45.344 c4.784,0,8.662-3.878,8.662-8.662C218.378,280.944,214.5,277.065,209.716,277.065z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M135.497,277.065h-80.55c-4.784,0-8.662,3.878-8.662,8.662c0,4.784,3.878,8.662,8.662,8.662h80.55 c4.784,0,8.662-3.878,8.662-8.662C144.16,280.944,140.281,277.065,135.497,277.065z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M209.716,236.737H54.947c-4.784,0-8.662,3.878-8.662,8.662c0,4.784,3.878,8.662,8.662,8.662h154.768 c4.784,0,8.662-3.878,8.662-8.662C218.378,240.615,214.5,236.737,209.716,236.737z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M209.716,317.393H54.947c-4.784,0-8.662,3.878-8.662,8.662s3.878,8.662,8.662,8.662h154.768 c4.784,0,8.662-3.878,8.662-8.662S214.5,317.393,209.716,317.393z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M209.716,357.721H128.18c-4.784,0-8.662,3.878-8.662,8.662s3.878,8.662,8.662,8.662h81.534 c4.784,0,8.662-3.878,8.662-8.662S214.5,357.721,209.716,357.721z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M99.306,357.721H54.947c-4.784,0-8.662,3.878-8.662,8.662s3.878,8.662,8.662,8.662h44.358 c4.784,0,8.662-3.878,8.662-8.662S104.09,357.721,99.306,357.721z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M394.683,135.771h-6.351v-10.395c0-4.784-3.877-8.662-8.662-8.662c-4.785,0-8.662,3.878-8.662,8.662v10.395h-6.354 c-4.785,0-8.662,3.878-8.662,8.662c0,4.784,3.877,8.662,8.662,8.662h6.354v21.945c0,4.784,3.877,8.662,8.662,8.662 c4.785,0,8.662-3.878,8.662-8.662v-21.945h6.351c4.785,0,8.662-3.878,8.662-8.662 C403.346,139.649,399.468,135.771,394.683,135.771z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M147.346,135.771h-6.352v-10.395c0-4.784-3.878-8.662-8.662-8.662c-4.784,0-8.662,3.878-8.662,8.662v10.395h-6.352 c-4.784,0-8.662,3.878-8.662,8.662c0,4.784,3.878,8.662,8.662,8.662h6.352v21.945c0,4.784,3.878,8.662,8.662,8.662 s8.662-3.878,8.662-8.662v-21.945h6.352c4.784,0,8.662-3.878,8.662-8.662C156.009,139.649,152.13,135.771,147.346,135.771z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
