import { useDispatch, useSelector } from 'react-redux';
import { addDisplayedHT, deleteDisplayedHT, getDisplayedHelpTexts, getShowAllHelpText } from '@soulhx/client-store';

export const MAX_HELPTEXT_ALLOWED_ONSCREEN = 3;

interface UseHTAllowedOptions {
  /**
   * Indicates that this HT should *always* be shown, so this hook
   * doesn't need to worry about it.
   */
  firstLevel?: boolean;

  /**
   * Indicates that the hook should skip processing this HT ID. It
   * differs from `firstLevel` in two ways:
   *
   * 1. `firstLevel` indicates that the hook should *never* look at the
   * HT ID, whereas `skip` might be used to indicate that the HT had
   * previously been shown, but now should not be.
   * 2. When `skip` is true, the behaviour is different: if the ID exists
   * in the `displayedHTs` value, it will be **removed**. This is because
   * a HT may initially be set to true as a default, and then when the user's
   * settings are properly loaded might get set back to false, in which case
   * we don't want it taking up a slot in the `displayedHTs` list.
   */
  skip?: boolean;
}

/**
 * Helper function (implemented as hook so we can use more of React's
 * infrastructure) to indicate whether a Help Text item can be shown
 * on the screen. The idea is to limit the number of active HTs
 * showing, so the user doesn't get overwhelmed.
 *
 * The full name of the HT should be passed (i.e.
 * `helpText.some.fullValue`).
 *
 * @param htName Name of the Help Text to be displayed
 * @param firstLevel Hack: ignores logic and returns true without modifying redux state
 * @returns true if it can be shown on the screen
 */
export const useHelpTextAllowed = (
  htName: string,
  { firstLevel = false, skip = false }: UseHTAllowedOptions = {}
): boolean => {
  const displayedHTs = useSelector(getDisplayedHelpTexts);
  const showAllHelpText = useSelector(getShowAllHelpText);
  const dispatch = useDispatch();

  if (firstLevel || showAllHelpText) return true;

  if (skip) {
    if (displayedHTs.includes(htName)) dispatch(deleteDisplayedHT(htName));
    return true;
  }

  if (displayedHTs.includes(htName)) return true;
  if (displayedHTs.length >= MAX_HELPTEXT_ALLOWED_ONSCREEN) return false;

  dispatch(addDisplayedHT(htName));
  return true;
};
