import React from 'react';
import { IIconProps } from '../../dm/icons.js';

export const JournalIcon = ({ color, size, className }: IIconProps) => {
  return (
    <svg
      fill={color}
      height={size}
      width={size}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 503.61 503.61"
      xmlSpace="preserve"
      className={className}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <g>
              <path d="M291.313,2.459C289.735,0.881,287.603,0,285.379,0H8.395C3.762,0,0.002,3.76,0.002,8.393v486.82 c0,4.633,3.76,8.393,8.393,8.393H360.92c4.633,0,8.393-3.76,8.393-8.393V83.934c0-2.224-0.881-4.365-2.459-5.934L291.313,2.459z M117.51,167.869h134.295c4.633,0,8.393,3.76,8.393,8.393s-3.76,8.393-8.393,8.393H117.51c-4.633,0-8.393-3.76-8.393-8.393 S112.877,167.869,117.51,167.869z M251.805,386.098H117.51c-4.633,0-8.393-3.76-8.393-8.393c0-4.633,3.76-8.393,8.393-8.393 h134.295c4.633,0,8.393,3.76,8.393,8.393C260.198,382.338,256.438,386.098,251.805,386.098z M285.379,335.738H83.936 c-4.633,0-8.393-3.76-8.393-8.393c0-4.633,3.76-8.393,8.393-8.393h201.443c4.633,0,8.393,3.76,8.393,8.393 C293.772,331.977,290.012,335.738,285.379,335.738z M109.116,276.984c0-4.633,3.76-8.393,8.393-8.393h134.295 c4.633,0,8.393,3.76,8.393,8.393c0,4.633-3.76,8.393-8.393,8.393H117.51C112.877,285.377,109.116,281.617,109.116,276.984z M285.379,235.016H83.936c-4.633,0-8.393-3.76-8.393-8.393s3.76-8.393,8.393-8.393h201.443c4.633,0,8.393,3.76,8.393,8.393 S290.012,235.016,285.379,235.016z M293.772,75.541V28.655l46.886,46.886H293.772z"></path>
              <path d="M495.215,41.967V20.984C495.215,9.409,485.806,0,474.231,0h-8.393c-11.575,0-20.984,9.409-20.984,20.984v20.984 c-4.633,0-8.393,3.76-8.393,8.393v3.206l-28.932,14.462c-0.386,0.193-0.629,0.52-0.974,0.764 c-0.487,0.344-0.99,0.646-1.393,1.083c-0.378,0.411-0.621,0.881-0.906,1.351c-0.277,0.445-0.579,0.865-0.772,1.368 c-0.218,0.571-0.269,1.175-0.361,1.779c-0.059,0.403-0.235,0.755-0.235,1.167v109.115c0,4.633,3.76,8.393,8.393,8.393 s8.393-3.76,8.393-8.393V80.728l16.787-8.393v213.042h67.148V50.361C503.608,45.727,499.848,41.967,495.215,41.967z M478.428,41.967h-16.787V20.984c0-2.317,1.88-4.197,4.197-4.197h8.393c2.317,0,4.197,1.88,4.197,4.197V41.967z"></path>
              <rect x="436.461" y="369.311" width="67.148" height="16.787"></rect>
              <rect x="436.461" y="402.885" width="67.148" height="16.787"></rect>
              <rect x="436.461" y="335.738" width="67.148" height="16.787"></rect>
              <path d="M453.678,481.082c1.142,3.425,4.348,5.741,7.965,5.741v8.393c0,4.633,3.752,8.393,8.393,8.393 c4.633,0,8.393-3.76,8.393-8.393v-8.393c3.609,0,6.815-2.317,7.957-5.741l14.882-44.62h-62.464L453.678,481.082z"></path>
              <rect x="436.461" y="302.164" width="67.148" height="16.787"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
