import React from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getNewVersionReload, updateNewVersionReload } from '@soulhx/client-store';
import { useTranslation } from 'react-i18next';

export const NewVersionReload = () => {
  const newVersionReload = useSelector(getNewVersionReload);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  interface IDelayBtn {
    numMinutes: number;
  }
  const DelayBtn = ({ numMinutes }: IDelayBtn) => {
    return (
      <Button
        variant="warning"
        onClick={() => {
          dispatch(updateNewVersionReload(false));

          setTimeout(() => {
            dispatch(updateNewVersionReload(true));
          }, 1000 * 60 * numMinutes);
        }}
      >
        {t('reload.delayBtn', { numMinutes })}
      </Button>
    );
  };

  return (
    <Modal show={newVersionReload} centered backdrop="static" animation>
      <Modal.Header closeButton={false}>
        <Modal.Title>{t('general.appName')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={3} direction="vertical">
          <div>{t('reload.reloadMsg')}</div>

          <Button
            variant="success"
            onClick={() => {
              dispatch(updateNewVersionReload(false));
              window.location.reload();
            }}
          >
            {t('reload.reloadBtn')}
          </Button>

          <DelayBtn numMinutes={5} />
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
