import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { CLICK_COMMAND, COMMAND_PRIORITY_LOW, LexicalEditor } from 'lexical';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection.js';
import { mergeRegister } from '@lexical/utils';

export interface IAllUpperEditor {
  nodeKey: string;
  text: string;
  editor: LexicalEditor;
  changeCallback: (newText: string) => void;
}
export const AllUpperEditor = ({ nodeKey, text, editor, changeCallback }: IAllUpperEditor) => {
  const mainSpanRef = useRef<HTMLSpanElement | null>(null);
  const mainEditRef = useRef<HTMLInputElement | null>(null);
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);

  const spans = useMemo(() => {
    const spans: JSX.Element[] = [];
    const individualWords = text.split(' ');

    for (let i = 0; i < individualWords.length; i++) {
      const theWord = individualWords[i];

      const firstLetter = theWord.substring(0, 1);
      const restOfWord = theWord.substring(1, theWord.length);
      spans.push(
        <span key={`${text}-${theWord}-${i}-first`} style={{ textTransform: 'uppercase' }}>
          {firstLetter}
        </span>
      );
      spans.push(
        <span key={`${text}-${theWord}-${i}-remaining`} style={{ textTransform: 'uppercase', fontSize: '1.3ex' }}>
          {restOfWord}
        </span>
      );

      if (i < individualWords.length - 1) {
        spans.push(<span key={`${text}-${theWord}-${i}-space`}> </span>);
      }
    }

    return spans;
  }, [text]);

  const spanIsSelectedByClick = (event: MouseEvent, theSpan: MutableRefObject<HTMLSpanElement | null>): boolean => {
    if (!theSpan.current) return false;

    if (event.target === theSpan.current) return true;

    for (const c of theSpan.current.childNodes) {
      if (event.target === c) return true;
    }

    return false;
  };

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        (payload) => {
          if (isSelected) {
            return true;
          }

          if (spanIsSelectedByClick(payload, mainSpanRef)) {
            clearSelection();
            setSelected(!isSelected);

            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerUpdateListener(() => {})
    );

    return () => {
      unregister();
    };
  }, [clearSelection, editor, isSelected, setSelected]);

  return isSelected ? (
    <input
      type="text"
      name="allUpperEditor"
      id="allUpperEditor"
      size={text.length}
      value={text}
      ref={mainEditRef}
      onChange={(e) => {
        const newTxt = e.currentTarget.value;
        changeCallback(newTxt);
      }}
    />
  ) : (
    <span ref={mainSpanRef}>{spans}</span>
  );
};
