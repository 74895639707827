import { MdastImportVisitor } from '@mdxeditor/editor';
import { $createBibleLinkNode, BibleLink } from './BibleLinkNode.js';

export const MdastBibleLinkVisitor: MdastImportVisitor<BibleLink> = {
  testNode: 'bibleLink',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto(
      $createBibleLinkNode(mdastNode.reference, {
        bibleVersion: mdastNode.bibleVersion,
        renderedText: mdastNode.renderedText,
        flags: mdastNode.flags,
      })
    );
  },
};
