import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { useGetSubscribedPlansQuery } from '../../services/PlansService.js';
import { updateDateForReadingPlan } from '@soulhx/client-store';
import { getDateRangeForPlans } from './helpers/helper-functions.js';
import { useTranslation } from 'react-i18next';
import { CalendarCalloutItem } from '../common/calendar/helpers/dm.js';
import { CalendarWidget } from '../common/calendar/CalendarWidget.js';

enum DayStatus {
  completed = 'Completed',
  partiallyCompleted = 'Partially Completed',
  notCompleted = 'Not Completed',
}

interface IPlanCalendarView {
  dateToShow: DateTime;
  clickHandler?: () => void;
}
export const PlanCalendarView = ({ dateToShow, clickHandler }: IPlanCalendarView) => {
  const { data } = useGetSubscribedPlansQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation(['plan']);

  const dayClickedInCalendar = useCallback(
    (value: Date) => {
      const dateToDispatch = DateTime.fromJSDate(value);
      dispatch(updateDateForReadingPlan(dateToDispatch.toISODate()!));
      if (clickHandler) clickHandler();
    },
    [dispatch, clickHandler]
  );

  const dayList = new Map<string, DayStatus>();

  if (data) {
    for (const p of data!) {
      if (!p.days) continue;

      for (const d of p.days!) {
        const currentDate = d.scheduledDate;
        const currentDayCompleted = d.completed;

        if (!dayList.has(currentDate)) {
          dayList.set(currentDate, currentDayCompleted ? DayStatus.completed : DayStatus.notCompleted);
        } else {
          const stateInMap = dayList.get(currentDate);
          let newState: DayStatus = DayStatus.notCompleted;

          if (currentDayCompleted) {
            if (stateInMap === DayStatus.completed) {
              newState = DayStatus.completed;
            } else {
              newState = DayStatus.partiallyCompleted;
            }
          } else {
            if (stateInMap === DayStatus.completed || stateInMap === DayStatus.partiallyCompleted) {
              newState = DayStatus.partiallyCompleted;
            } else {
              newState = DayStatus.notCompleted;
            }
          }

          dayList.set(currentDate, newState);
        }
      }
    }
  }

  const completedCallout: CalendarCalloutItem = {
    name: t('plan:calendar.completedCategory'),
    dates: new Set<string>(),
  };
  const partialsCallout: CalendarCalloutItem = {
    name: t('plan:calendar.partialCategory'),
    dates: new Set<string>(),
  };
  const incompleteCallout: CalendarCalloutItem = {
    name: t('plan:calendar.incompleteCategory'),
    dates: new Set<string>(),
  };

  dayList.forEach((value, key) => {
    if (value === DayStatus.completed) {
      completedCallout.dates.add(key);
    }
    if (value === DayStatus.partiallyCompleted) {
      partialsCallout.dates.add(key);
    }
    if (value === DayStatus.notCompleted) {
      incompleteCallout.dates.add(key);
    }
  });

  const callouts: CalendarCalloutItem[] = [completedCallout, partialsCallout, incompleteCallout];

  const [earliestDate, latestDate] = getDateRangeForPlans(data);

  return (
    <CalendarWidget
      dateToShow={dateToShow}
      selectNewDate={dayClickedInCalendar}
      startDateChange={() => {}}
      minDate={earliestDate}
      maxDate={latestDate}
      callouts={callouts}
      hardcodedSelectedValues={[
        t('plan:calendar.completedCategory'),
        t('plan:calendar.partialCategory'),
        t('plan:calendar.incompleteCategory'),
      ]}
    />
  );
};
