import React from 'react';
import { Transformer } from '@lexical/markdown';
import { $createMarkNode, $isMarkNode, MarkNode } from '../text-plugins/nodes/MarkNode.js';
import { $createTextNode } from 'lexical';
import { $createSupNode, $isSupNode, SupNode } from '../text-plugins/nodes/SuperscriptNode.js';
import { $createSmallCapsNode, $isSmallCapsNode, SmallCapsNode } from '../text-plugins/nodes/SmallCapsNode.js';
import { $createEraNode, $isEraNode, EraNode } from '../text-plugins/nodes/EraNode.js';
import { $createAllUpperNode, $isAllUpperNode, AllUpperNode } from '../text-plugins/nodes/AllUpperNode.js';
import { addComposerChild$, addNestedEditorChild$, realmPlugin } from '@mdxeditor/editor';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';

const transformers: Transformer[] = [
  {
    type: 'text-format',
    tag: '~~',
    format: ['strikethrough'],
  },
  {
    type: 'text-match',
    dependencies: [MarkNode],
    importRegExp: /==([^=]+)==/,
    regExp: /==([^=]+)==/,
    export: (node, exportChildren) => {
      if (!$isMarkNode(node)) return null;

      return exportChildren(node);
    },
    replace: (textNode, match) => {
      const markNode = $createMarkNode({ type: 'mark', children: [] });
      const childTextNode = $createTextNode(match[1]);
      childTextNode.setFormat(textNode.getFormat());
      markNode.append(childTextNode);
      textNode.replace(markNode);
    },
    trigger: '=',
  },
  {
    type: 'text-match',
    dependencies: [SupNode],
    // lookbehind is not ^; exactly 1 ^; non-^ chars; exactly 1 ^; lookahead not ^;
    importRegExp: /(?<!\^)\^{1}([^^]+)\^{1}(?!\^)/,
    regExp: /(?<!\^)\^{1}([^^]+)\^{1}(?!\^)/,
    export: (node, exportChildren) => {
      if (!$isSupNode(node)) return null;

      return exportChildren(node);
    },
    replace: (textNode, match) => {
      const supNode = $createSupNode({ type: 'sup', children: [] });
      const childTextNode = $createTextNode(match[1]);
      childTextNode.setFormat(textNode.getFormat());
      supNode.append(childTextNode);
      textNode.replace(supNode);
    },
    trigger: '^',
  },
  {
    type: 'text-match',
    dependencies: [SmallCapsNode],
    importRegExp: /(?<!\^)\^{4}([^^]+)\^{4}(?!\^)/,
    regExp: /(?<!\^)\^{4}([^^]+)\^{4}(?!\^)/,
    export: (node, exportChildren) => {
      if (!$isSmallCapsNode(node)) return null;

      return exportChildren(node);
    },
    replace: (textNode, match) => {
      const supNode = $createSmallCapsNode({ type: 'smallcaps', children: [] });
      const childTextNode = $createTextNode(match[1]);
      childTextNode.setFormat(textNode.getFormat());
      supNode.append(childTextNode);
      textNode.replace(supNode);
    },
    trigger: '^',
  },
  {
    type: 'text-match',
    dependencies: [EraNode],
    importRegExp: /(?<!\^)\^{2}([^^]+)\^{2}(?!\^)/,
    regExp: /(?<!\^)\^{2}([^^]+)\^{2}(?!\^)/,
    export: (node, exportChildren) => {
      if (!$isEraNode(node)) return null;

      return exportChildren(node);
    },
    replace: (textNode, match) => {
      const supNode = $createEraNode({ type: 'era', children: [] });
      const childTextNode = $createTextNode(match[1]);
      childTextNode.setFormat(textNode.getFormat());
      supNode.append(childTextNode);
      textNode.replace(supNode);
    },
    trigger: '^',
  },
  {
    type: 'text-match',
    dependencies: [AllUpperNode],
    importRegExp: /(?<!\^)\^{3}([^^]+)\^{3}(?!\^)/,
    regExp: /(?<!\^)\^{3}([^^]+)\^{3}(?!\^)/,
    export: (node) => {
      if (!$isAllUpperNode(node)) return null;

      return node.getTheText();
    },
    replace: (textNode, match) => {
      const supNode = $createAllUpperNode(match[1].toLocaleUpperCase());
      textNode.replace(supNode);
    },
    trigger: '^',
  },
];

export const shxShortcutsPlugin = realmPlugin({
  init(r) {
    r.pubIn({
      [addComposerChild$]: () => <MarkdownShortcutPlugin transformers={transformers} />,
      [addNestedEditorChild$]: () => <MarkdownShortcutPlugin transformers={transformers} />,
    });
  },
});
