import { ElementNode, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical';
import { Parent, Node, PhrasingContent } from 'mdast';

export interface Mark extends Parent, Node {
  type: 'mark';
  children: PhrasingContent[];
}

export type SerializedMarkNode = Spread<
  {
    mdastNode: Mark;
  },
  SerializedLexicalNode
>;

export class MarkNode extends ElementNode {
  __mdastNode: Mark;

  static getType() {
    return 'mark';
  }

  static clone(node: MarkNode): MarkNode {
    return new MarkNode(structuredClone(node.__mdastNode), node.__key);
  }

  static importJSON(serializedNode: SerializedMarkNode): MarkNode {
    return $createMarkNode(serializedNode.mdastNode);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'mark',
    };
  }

  getMdastNode() {
    return this.__mdastNode;
  }

  constructor(mdastNode?: Mark, key?: NodeKey) {
    super(key);
    this.__mdastNode = mdastNode ?? { type: 'mark', children: [] };
  }

  createDOM() {
    return document.createElement('mark');
  }

  updateDOM() {
    return false;
  }

  isInline() {
    return true;
  }
}

export function $isMarkNode(node: LexicalNode | null | undefined): node is MarkNode {
  return node instanceof MarkNode;
}

export function $createMarkNode(mdastNode: Mark): MarkNode {
  return new MarkNode(mdastNode);
}
