import React from 'react';
import { getShowHoverText } from '@soulhx/client-store';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';

interface ITooltipElement {
  /**
   * Text to show in the tooltip.
   *
   * Should almost always be provided, but set to optional for
   * cases where a component needs to optionally generate its
   * tooltip (which could result in `undefined`); if not provided,
   * the tooltip won't be rendered.
   *
   * Should not typically be used to prevent tooltip generation;
   * user's settings (which are respected by this component)
   * should be used for that.
   */
  tooltip?: string;

  /**
   * If provided, the rendered TT will have a title/header
   * section with this text.
   */
  title?: string;

  /**
   * Content to be rendered. Can be text. Typically provided as
   * an element within the `<TooltipElement>` component, but
   * could be passed as a param instead.
   */
  children: JSX.Element;
}
/**
 * Renders content that provides a tooltip. Handles the user setting
 * for same, such that TTs aren't shown if the user has de-selected
 * the option.
 */
export const TooltipElement = ({ tooltip, title, children }: ITooltipElement) => {
  const showTooltips = useSelector(getShowHoverText);

  if (!showTooltips) return children;

  return (
    <OverlayTrigger
      placement="auto-end"
      delay={{ show: 250, hide: 400 }}
      rootClose
      trigger={['focus', 'hover']}
      overlay={
        <Popover>
          {title && <Popover.Header>{title}</Popover.Header>}
          <Popover.Body>{tooltip}</Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};
