import { MdastImportVisitor } from '@mdxeditor/editor';
import { $createSmallCapsNode, SmallCaps } from './nodes/SmallCapsNode.js';
import { $createMarkNode, Mark } from './nodes/MarkNode.js';
import { $createEraNode, Era } from './nodes/EraNode.js';
import { $createSupNode, Sup } from './nodes/SuperscriptNode.js';
import { $createAllUpperNode, AllUpper } from './nodes/AllUpperNode.js';

export const MdastSmallCapsVisitor: MdastImportVisitor<SmallCaps> = {
  testNode: 'smallcaps',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto($createSmallCapsNode(mdastNode));
  },
};

export const MdastMarkVisitor: MdastImportVisitor<Mark> = {
  testNode: 'mark',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto($createMarkNode(mdastNode));
  },
};

export const MdastEraVisitor: MdastImportVisitor<Era> = {
  testNode: 'era',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto($createEraNode(mdastNode));
  },
};

export const MdastSupVisitor: MdastImportVisitor<Sup> = {
  testNode: 'sup',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto($createSupNode(mdastNode));
  },
};

export const MdastAllUpperVisitor: MdastImportVisitor<AllUpper> = {
  testNode: 'allupper',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto($createAllUpperNode(mdastNode.theText));
  },
};
