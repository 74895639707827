/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addActivePlugin$,
  addExportVisitor$,
  addImportVisitor$,
  addLexicalNode$,
  addMdastExtension$,
  addSyntaxExtension$,
  addToMarkdownExtension$,
  realmPlugin,
} from '@mdxeditor/editor';
import { MdastBibleLinkVisitor } from './MdastVisitor.js';
import { BibleLinkVisitor } from './LexicalVisitor.js';
import { shxBibleLink } from './bible-link-syntax.js';
import { Options as ToMarkdownExtension } from 'mdast-util-to-markdown';
import { Extension as FromMarkdownExtension } from 'mdast-util-from-markdown';
import { BibleLinkNode } from './BibleLinkNode.js';

declare module 'mdast-util-to-markdown' {
  interface ConstructNameMap {
    bibleLink: 'bibleLink';
  }
}

export function shxBibleLinkFromMarkdown(): FromMarkdownExtension {
  return {
    enter: {
      bibleLink: function (this, token) {
        this.enter({ type: 'bibleLink', reference: '', children: [] }, token);
      },
    },
    exit: {
      bibleLink: function (this, token) {
        this.exit(token);
      },
      reference: function (this, token) {
        const ref = this.sliceSerialize(token).trim();
        const current = this.stack[this.stack.length - 1];
        (current as any).reference = ref;
      },
      renderedText: function (this, token) {
        const renderedText = this.sliceSerialize(token);
        const current = this.stack[this.stack.length - 1];
        (current as any).renderedText = renderedText;
      },
      bibleVersion: function (this, token) {
        const bibleVersion = this.sliceSerialize(token);
        const current = this.stack[this.stack.length - 1];
        (current as any).bibleVersion = bibleVersion;
      },
      flags: function (this, token) {
        const flags = this.sliceSerialize(token).replace(/;/g, '');
        const current = this.stack[this.stack.length - 1];
        (current as any).flags = flags;
      },
    },
  };
}

export function shxBibleLinkToMarkdown(): ToMarkdownExtension {
  return {
    unsafe: [],
    handlers: {
      bibleLink: (node, parents, state, info) => {
        const tracker = state.createTracker(info);
        const exit = state.enter('bibleLink');
        let value = tracker.move('[|');
        value += tracker.move(node.reference);

        if (node.renderedText) {
          value += tracker.move(' (');
          value += tracker.move(node.renderedText);
          value += tracker.move(')');
        }
        value += tracker.move('|');

        if (node.bibleVersion) {
          value += tracker.move(node.bibleVersion);
        }

        if (node.flags) {
          value += tracker.move(';');
          value += tracker.move(node.flags);
        }

        value += tracker.move(']');

        exit();
        return value;
      },
    },
  };
}

export const bibleLinkPlugin = realmPlugin<{
  defaultVersion?: string;
  passageContext?: string;
}>({
  init(r) {
    r.pubIn({
      [addActivePlugin$]: 'bibleLink',
      [addImportVisitor$]: MdastBibleLinkVisitor,
      [addLexicalNode$]: BibleLinkNode,
      [addExportVisitor$]: BibleLinkVisitor,
      [addSyntaxExtension$]: shxBibleLink(),
      [addMdastExtension$]: shxBibleLinkFromMarkdown(),
      [addToMarkdownExtension$]: shxBibleLinkToMarkdown(),
    });
  },
});
