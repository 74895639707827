import React from 'react';
import { escapeForURL } from '@soulhx/fs-common';

interface IOnlineBibleViewer {
  passage: string;
  version: string;
}
export const OnlineBibleViewer = ({ passage, version }: IOnlineBibleViewer) => {
  return (
    <div style={{ height: '60vh' }}>
      <iframe
        style={{ width: '100%', height: '100%' }}
        className="p-1"
        title="Bible Gateway"
        src={`https://www.biblegateway.com/passage/?search=${escapeForURL(passage)}&version=${escapeForURL(version)}`}
      />
    </div>
  );
};
