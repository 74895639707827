import { MdastImportVisitor } from '@mdxeditor/editor';
import { $createFootnoteDefinitionNode } from './nodes/FootnoteDefinitionNode.js';
import { $createFootnoteReferenceNode } from './nodes/FootnoteReferenceNode.js';
import { FootnoteDefinition, FootnoteReference } from 'mdast';

export const MdastFootnoteDefinitionVisitor: MdastImportVisitor<FootnoteDefinition> = {
  testNode: 'footnoteDefinition',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto($createFootnoteDefinitionNode(mdastNode.identifier, mdastNode.label));
  },
};

export const MdastFootnoteReferenceVisitor: MdastImportVisitor<FootnoteReference> = {
  testNode: 'footnoteReference',
  visitNode({ actions, mdastNode }) {
    actions.addAndStepInto($createFootnoteReferenceNode(mdastNode.identifier, mdastNode.label));
  },
};
