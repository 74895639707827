import React from 'react';
import { ApiErrorType, ErrorLoadingDataMessage, LoadingMessage } from '@soulhx/ui-common';

/**
 * Helper function to be called at the beginning of a React
 * component to handle the checks that are required every time
 * a component relies on **RTQ** API calls:
 *
 * 1. Have the API(s) completed loading?
 * 1. Were any errors returned?
 *
 * These checks were being made at the beginning of each component,
 * so this is a _slight_ timesaver.
 *
 * @example
 * const resp = handleInit([isLoading], [error]);
 * if ( resp ) return resp;
 *
 * @example
 * const resp = handleInit([isLoading, isOtherLoading], [error, otherError]);
 * if ( resp ) return resp;
 *
 * @param loadingElements List of boolean `isLoading` responses from RTQ
 * @param errorObjects List of errors returned from RTQ
 * @returns Either a `JSX.Element` to be rendered, or `undefined` if everything is good to go
 */
export const handleInit = (loadingElements: boolean[], errorObjects: ApiErrorType[]): JSX.Element | undefined => {
  if (loadingElements.includes(true)) return <LoadingMessage />;

  if (errorObjects.some((eo) => eo !== undefined)) {
    return <ErrorLoadingDataMessage errors={errorObjects} />;
  }

  return undefined;
};
