import React from 'react';
import { IIconProps } from '../../dm/icons.js';

export const PodcastIcon = ({ size, color, className }: IIconProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      className={className}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g id="style=fill" clipPath="url(#clip0_1_2557)">
          <g id="podcast">
            <path
              id="vector (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.75024 5C7.75024 2.65279 9.65303 0.75 12.0002 0.75C14.3475 0.75 16.2502 2.65279 16.2502 5V12C16.2502 14.3472 14.3475 16.25 12.0002 16.25C9.65303 16.25 7.75024 14.3472 7.75024 12V5Z"
              fill={color}
            ></path>
            <path
              id="vector (Stroke)_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.0002 17.75C12.4145 17.75 12.7502 18.0858 12.7502 18.5V22.5C12.7502 22.9142 12.4145 23.25 12.0002 23.25C11.586 23.25 11.2502 22.9142 11.2502 22.5V18.5C11.2502 18.0858 11.586 17.75 12.0002 17.75Z"
              fill={color}
            ></path>
            <path
              id="vector (Stroke)_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.00024 10.75C5.41446 10.75 5.75024 11.0858 5.75024 11.5C5.75024 12.3208 5.91191 13.1335 6.226 13.8918C6.54009 14.6501 7.00046 15.3391 7.58083 15.9194C8.16119 16.4998 8.85019 16.9602 9.60847 17.2742C10.3668 17.5883 11.1795 17.75 12.0002 17.75C12.821 17.75 13.6337 17.5883 14.392 17.2742C15.1503 16.9602 15.8393 16.4998 16.4197 15.9194C17 15.3391 17.4604 14.6501 17.7745 13.8918C18.0886 13.1335 18.2502 12.3208 18.2502 11.5C18.2502 11.0858 18.586 10.75 19.0002 10.75C19.4145 10.75 19.7502 11.0858 19.7502 11.5C19.7502 12.5177 19.5498 13.5255 19.1603 14.4658C18.7708 15.4061 18.2 16.2604 17.4803 16.9801C16.7607 17.6997 15.9063 18.2706 14.966 18.6601C14.0258 19.0495 13.018 19.25 12.0002 19.25C10.9825 19.25 9.97472 19.0495 9.03445 18.6601C8.09417 18.2706 7.23982 17.6997 6.52017 16.9801C5.80051 16.2604 5.22965 15.4061 4.84018 14.4658C4.4507 13.5255 4.25024 12.5177 4.25024 11.5C4.25024 11.0858 4.58603 10.75 5.00024 10.75Z"
              fill={color}
            ></path>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1_2557">
            <rect width="24" height="24" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"></rect>
          </clipPath>
        </defs>
      </g>
    </svg>
  );
};
