import {
  addActivePlugin$,
  addExportVisitor$,
  addImportVisitor$,
  addLexicalNode$,
  addMdastExtension$,
  addSyntaxExtension$,
  addToMarkdownExtension$,
  realmPlugin,
} from '@mdxeditor/editor';
import { FootnoteReferenceNode } from './nodes/FootnoteReferenceNode.js';
import { FootnoteDefinitionNode } from './nodes/FootnoteDefinitionNode.js';
import { gfmFootnoteFromMarkdown, gfmFootnoteToMarkdown } from 'mdast-util-gfm-footnote';
import { gfmFootnote } from 'micromark-extension-gfm-footnote';
import { LexicalFootnoteDefinitionVisitor, LexicalFootnoteReferenceVisitor } from './LexicalVisitor.js';
import { MdastFootnoteDefinitionVisitor, MdastFootnoteReferenceVisitor } from './MdastVisitor.js';

export const footnotePlugin = realmPlugin({
  init(r) {
    r.pubIn({
      [addActivePlugin$]: ['footnoteReference', 'footnoteDefinition'],
      [addLexicalNode$]: [FootnoteReferenceNode, FootnoteDefinitionNode],
      [addSyntaxExtension$]: gfmFootnote(),
      [addMdastExtension$]: gfmFootnoteFromMarkdown(),
      [addToMarkdownExtension$]: gfmFootnoteToMarkdown(),
      [addExportVisitor$]: [LexicalFootnoteReferenceVisitor, LexicalFootnoteDefinitionVisitor],
      [addImportVisitor$]: [MdastFootnoteDefinitionVisitor, MdastFootnoteReferenceVisitor],
    });
  },
});
