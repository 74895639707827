/**
 * Helper function to perform the same work as `encodeURIComponent()`
 * except:
 *
 * * spaces are encoded as `+` instead of `%20`
 * * any m- or n-dashes are changed to hyphens
 *
 * Some documentation recommends modifying the return from
 * `encodeURIComponent()` to change the `%20` to `+` so this
 * helper does exactly that.
 *
 * @param theString The string to be encoded
 * @returns A string ready to be included as part of a URL
 */
export const escapeForURL = (theString: string): string => {
  return encodeURIComponent(theString.replaceAll('–', '-').replaceAll('—', '-')).replaceAll('%20', '+');
};
