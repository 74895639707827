import { escapeForURL, getFormattedRef } from '@soulhx/fs-common';

interface IGetUrl {
  context?: string;
  version?: string;
  includeVerses?: boolean;
}
export const getBGUrl = (
  passage: string,
  { context = undefined, version = undefined, includeVerses = true }: IGetUrl = {}
): string => {
  const searchString = escapeForURL(getFormattedRef(passage, { context, includeVerses }));

  const linkUrlStrings: string[] = [];
  linkUrlStrings.push('https://www.biblegateway.com/passage/?search=');
  linkUrlStrings.push(searchString);

  if (version) {
    linkUrlStrings.push('&version=', escapeForURL(version));
  }

  const linkUrl = linkUrlStrings.join('');
  return linkUrl;
};
