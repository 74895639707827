import { ElementNode, LexicalNode, NodeKey, SerializedLexicalNode, Spread } from 'lexical';
import { Parent, Node, PhrasingContent } from 'mdast';

export interface Era extends Parent, Node {
  type: 'era';
  children: PhrasingContent[];
}

export type SerializedEraNode = Spread<
  {
    mdastNode: Era;
  },
  SerializedLexicalNode
>;

export class EraNode extends ElementNode {
  __mdastNode: Era;

  static getType() {
    return 'era';
  }

  static clone(node: EraNode): EraNode {
    return new EraNode(structuredClone(node.__mdastNode), node.__key);
  }

  static importJSON(serializedNode: SerializedEraNode): EraNode {
    return $createEraNode(serializedNode.mdastNode);
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'era',
    };
  }

  getMdastNode() {
    return this.__mdastNode;
  }

  constructor(mdastNode?: Era, key?: NodeKey) {
    super(key);
    this.__mdastNode = mdastNode ?? { type: 'era', children: [] };
  }

  createDOM() {
    const element = document.createElement('span');
    element.style.fontVariant = 'small-caps';
    element.style.fontSize = '1.3ex';
    element.style.textTransform = 'uppercase';
    return element;
  }

  updateDOM() {
    return false;
  }

  isInline() {
    return true;
  }
}

export function $isEraNode(node: LexicalNode | null | undefined): node is EraNode {
  return node instanceof EraNode;
}

export function $createEraNode(mdastNode: Era): EraNode {
  return new EraNode(mdastNode);
}
