import React, { useMemo } from 'react';
import { ToolbarStyle } from 'dm/standard-types.js';
import { getPlugins } from './editor-plugins.js';
import { MDXEditor } from '@mdxeditor/editor';

export interface IMDEditor {
  /**
   * The [initial] markdown being edited
   */
  content: string;

  /**
   * Called when the MD is updated (i.e. as the user edits)
   *
   * @param newContent The content as currently in the editor
   */
  changeCallback: (newContent: string) => void;

  /**
   * (Optional) Default version of the Bible under which this
   * content is created
   */
  defaultVersion?: string;

  /**
   * (Optional) A larger passage of Scripture under which
   * this content is being created
   */
  passageContext?: string;

  /**
   * (Optional) Initial state of the toolbar
   *
   * * `hidden`: no toolbar shown
   * * `condensed`: version of the toolbar without the full
   * set of buttons
   * * `full`: The full toolbar is shown
   *
   * Defaults to `full`
   */
  initialToolbar?: ToolbarStyle;

  /**
   * (Optional) Indicates that the markdown should not
   * be editable. If set to `true` it's recommended
   * that `changeCallback` be set to an empty / do-
   * nothing function.
   *
   * Defaults to `false`
   */
  readOnly?: boolean;

  /**
   * (Optional) Shows the editor in markdown/source
   * mode.
   *
   * Treats `initialToolbar` as `hidden` if true.
   *
   * Defaults to `false`
   */
  sourceMode?: boolean;

  /**
   * Called when the editor loses focus (i.e. on blur)
   */
  loseFocusCallback?: () => void;
}

/**
 * Specialized SoulHX markdown editor, based on **MDXEditor**.
 */
export const MDEditor = ({
  content,
  changeCallback,
  defaultVersion,
  passageContext,
  initialToolbar = 'full',
  readOnly = false,
  sourceMode = false,
  loseFocusCallback,
}: IMDEditor) => {
  const plugins = useMemo(
    () => getPlugins(sourceMode, initialToolbar, defaultVersion, passageContext),
    [sourceMode, initialToolbar, defaultVersion, passageContext]
  );

  return (
    <MDXEditor
      markdown={content}
      plugins={plugins}
      readOnly={readOnly}
      onChange={changeCallback}
      onBlur={() => {
        if (loseFocusCallback) {
          loseFocusCallback();
        }
      }}
    />
  );
};
