import React, { useMemo, useState } from 'react';
import { Col, Collapse, Container, ListGroup, Row } from 'react-bootstrap';
import { useGetSpecialTypeIDsQuery } from '../../../services/ActionsService.js';
import { ActionButton, IActionButton } from './ActionButton.js';
import { SHXIcon, StandardButton, ErrorLoadingDataMessage } from '@soulhx/ui-common';
import { Check2, Check2All } from 'react-bootstrap-icons';
import { useGetActionTypes } from '../../../hooks/GetActions.js';
import { useTranslation } from 'react-i18next';
import { useUserSetting } from '../../../hooks/Setting.js';
import { OuterDiv } from './helpers/OuterDiv.js';
import { ShxHelpText } from '../ShxHelpText.js';

const iconSize = 25;

export const FloatingActionButtons = () => {
  const { actionTypes, actionTypeError, actionTypesLoading } = useGetActionTypes({ longNames: false });
  const {
    data: specialTypeIds,
    error: specialTypesError,
    isLoading: specialTypesLoading,
  } = useGetSpecialTypeIDsQuery();
  const { settingValue: showActionBtns } = useUserSetting<boolean>('generalShowActionBtns');
  const [showingBibleOptions, setShowingBibleOptions] = useState<boolean>(false);
  const { settingValue: showHTSetting } = useUserSetting<boolean>('helpText.home.actionButtons');
  const { t } = useTranslation(['do']);

  const buttons = useMemo(() => {
    if (!actionTypes || !specialTypeIds || !showActionBtns) return [];

    const topList: IActionButton[] = [
      {
        id: specialTypeIds!.church,
        children: <SHXIcon type="church" size={iconSize} />,
        toolTip: t('do:actionTypes.short.church'),
      },
      {
        id: specialTypeIds!.podcast,
        children: <SHXIcon type="podcast" size={iconSize} />,
        toolTip: t('do:actionTypes.short.podcast'),
      },
      {
        id: specialTypeIds!.prayer,
        children: <SHXIcon type="pray" size={iconSize} />,
        toolTip: t('do:actionTypes.short.prayer'),
      },
      {
        id: specialTypeIds!.journal,
        children: <SHXIcon type="journal" size={iconSize} />,
        toolTip: t('do:actionTypes.short.journal'),
      },
      {
        id: specialTypeIds!.book,
        children: <SHXIcon type="christianbook" size={iconSize} />,
        toolTip: t('do:actionTypes.short.book'),
      },
    ];

    const availableIds = new Set<string>();
    actionTypes!.forEach((item) => availableIds.add(item.id!));

    const helpTextBtn = showHTSetting
      ? [
          <ListGroup.Item className="p-0" key="floating-btns-helptext">
            <ShxHelpText pageName="home" textName="actionButtons" collapsed inline />
          </ListGroup.Item>,
        ]
      : [];

    const renderedTopList = topList
      .filter((item) => availableIds.has(item.id))
      .map((item) => (
        <ListGroup.Item className="p-0" key={`floating-btn-${item.id}`}>
          <ActionButton id={item.id} toolTip={item.toolTip}>
            {item.children}
          </ActionButton>
        </ListGroup.Item>
      ));

    const bibleList: IActionButton[] = [
      {
        id: specialTypeIds!.shortOT,
        children: (
          <>
            <SHXIcon type="oldtestament" size={iconSize} />
            <Check2 />
          </>
        ),
        toolTip: t('do:actionTypes.short.shortOT'),
      },
      {
        id: specialTypeIds!.longOT,
        children: (
          <>
            <SHXIcon type="oldtestament" size={iconSize} />
            <Check2All />
          </>
        ),
        toolTip: t('do:actionTypes.short.longOT'),
      },
      {
        id: specialTypeIds!.shortNT,
        children: (
          <>
            <SHXIcon type="newtestament" size={iconSize} />
            <Check2 />
          </>
        ),
        toolTip: t('do:actionTypes.short.shortNT'),
      },
      {
        id: specialTypeIds!.longNT,
        children: (
          <>
            <SHXIcon type="newtestament" size={iconSize} />
            <Check2All />
          </>
        ),
        toolTip: t('do:actionTypes.short.longOT'),
      },
    ];

    const filteredBibleList = bibleList.filter((item) => availableIds.has(item.id));

    const renderedBibleList =
      filteredBibleList.length > 0 ? (
        <ListGroup.Item className="p-0" key={`renderedBibleList`}>
          <StandardButton
            type="openCloseBible"
            done={showingBibleOptions}
            variant="outline-primary"
            onClick={() => setShowingBibleOptions(!showingBibleOptions)}
            tooltip={t('do:actionTypes.short.scripture')}
          />
          <Collapse in={showingBibleOptions}>
            <Container style={{ width: '100px' }}>
              <Row xs="2" className="p-0 m-0">
                {filteredBibleList.map((item) => (
                  <Col className="p-0 m-0" key={`bible-item-${item.id}`}>
                    <ActionButton id={item.id} toolTip={item.toolTip}>
                      {item.children}
                    </ActionButton>
                  </Col>
                ))}
              </Row>
            </Container>
          </Collapse>
        </ListGroup.Item>
      ) : (
        <></>
      );

    const otherItems = actionTypes!
      .filter((item) => !item.isDefault)
      .map((item) => (
        <ListGroup.Item className="p-0" key={`other-floating-item-${item.id!}`}>
          <ActionButton id={item.id!}>{item.displayName}</ActionButton>
        </ListGroup.Item>
      ));

    let returnList = helpTextBtn;
    if (renderedTopList.length > 0) returnList = returnList.concat(renderedTopList);
    if (filteredBibleList.length > 0) returnList = returnList.concat(renderedBibleList);
    if (otherItems.length > 0) returnList = returnList.concat(otherItems);
    return returnList;
  }, [actionTypes, specialTypeIds, showingBibleOptions, showHTSetting, t]);

  if (!showActionBtns) return <></>;
  if (actionTypesLoading || specialTypesLoading) return <></>;
  if (actionTypeError || specialTypesError) {
    return (
      <OuterDiv>
        <ErrorLoadingDataMessage errors={[actionTypeError, specialTypesError]} />
      </OuterDiv>
    );
  }

  return (
    <OuterDiv>
      <ListGroup variant="flush">{buttons}</ListGroup>
    </OuterDiv>
  );
};

export default FloatingActionButtons;
